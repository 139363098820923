import { Auth } from 'aws-amplify';
import {CognitoUser} from "@aws-amplify/auth";
import jwtDecode from "jwt-decode";

let user: CognitoUser | null = null;

export const getCurrentUser = async (): Promise<CognitoUser | null> => {
    if (user) {
        return user;
    }

    try {
        user = await Auth.currentAuthenticatedUser();
        console.log(user);
        return user;
    } catch (error) {
        console.log('Error getting current user:', error);
        await Auth.federatedSignIn();
        return null;
    }
};


export const getCurrentUserTokenId = async (): Promise<string | null> => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const session = currentUser.getSignInUserSession();
        if (session) {
            const tokenId = session.getIdToken().getJwtToken();
            return tokenId;
        } else {
            throw new Error('User session not found');
        }
    } catch (error) {
        console.log('Error getting current user token ID:', error);
        await Auth.federatedSignIn();
        return null;
    }
};

export const getCurrentUserRoles = async (): Promise<string[]> => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.getSignInUserSession()?.getIdToken().getJwtToken();
        if (token) {
            const roles = extractValues(getTokenPropertyByKeyName(String(token), 'custom:groups'));
            const roles2 = extractValues(getTokenPropertyByKeyName(String(token), 'cognito:groups'));
            return roles.concat(roles2);
        } else {
            throw new Error('User session not found');
        }
    } catch (error) {
        console.log('Error getting current user roles:', error);
        await Auth.federatedSignIn();
        return [];
    }
};

export const getCurrentUserNameAndRoles = async (): Promise<{ user: string | null, roles : string[] , login: string | null }> => {
    try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const token = currentUser.getSignInUserSession()?.getIdToken().getJwtToken();
        if (token) {
            const name = getTokenPropertyByKeyName(String(token), 'name')
            const login = getTokenPropertyByKeyName(String(token), 'email')
            const roles = extractValues(getTokenPropertyByKeyName(String(token), 'custom:groups'));
            const roles2 = extractValues(getTokenPropertyByKeyName(String(token), 'cognito:groups'));

            return { user: name, roles: roles.concat(roles2) , login: login };
        } else {
            throw new Error('User session not found');
        }
    } catch (error) {
        console.log('Error getting current user roles:', error);
        await Auth.federatedSignIn();
        return { user: null, roles: [], login: null };
    }
};

export const getTokenPropertyByKeyName = (token: string, keyName: string): any | null => {
    const decodedToken: { [key: string]: any } = jwtDecode(token);
    return decodedToken[keyName] || null;
};


export const signOut = async () => {
    try{
        await Auth.signOut();

    } catch (error) {
        console.log('Sign out error:', error);
    }
};

function extractValuesFromString(input: string): string[] {
    if (input == null) return [];

    const prefix = "PMIGLOBALURCannaBoRN";
    const suffixes = ["DEVIGA", "QASIGA", "PRDIGA"];

    const values: string[] = [];

    const regexPattern = new RegExp(`${prefix}(.*?)(${suffixes.join('|')})`, 'g');
    const matches = input.match(regexPattern);

    if (matches) {
        for (const match of matches) {
            const value = match.replace(prefix, '').replace(new RegExp(`(?:${suffixes.join('|')})$`), '');
            values.push(value);
        }
    }

    return values;
}

function extractValuesFromArray(inputArray: string[]): string[] {
    const values: string[] = [];

    for (const input of inputArray) {
        const extractedValues = extractValuesFromString(input);
        values.push(...extractedValues);
    }

    return values;
}


function extractValues(input: any): string[] {

    if (input == null) return [];

    if (typeof input === 'string') {
        return extractValuesFromString(input);
    }else {
        return extractValuesFromArray(input);
    }
}


