import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {deleteDoc, getStudiesRegEx, save, STUDIES_METABOLITES, getObjectByDoc} from '../../../api/Studies';
import { iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {Metabolite} from "./types";
import {isNotEmpty, isNotUndefined} from "../../../utils/string";
import FormEditModal from "../../common/FormEditModal";
import {renderFields} from "../../../utils/pair_entry";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {useRoles} from "../../../App.Context";

const _MetaboliteCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                   setData, data , isActive,
                                                   setTitle, setSubtitle, searchByDoc }) => {
    const UUID = '_MetaboliteCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK
    const saveData = async (data: any) => {
        save(STUDIES_METABOLITES, data).then((res) => {
            setData(res);
        }).catch(setError);
    }


    const deleteData = async (data: any) => {
        deleteDoc(STUDIES_METABOLITES, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }


    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(STUDIES_METABOLITES, data).then((res) => {
            setData(res);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };


    // SEARCH ----------------------

    function _search(text: string) {
        if (text.length > 0) {
            setTitle(text);
            getStudiesRegEx(STUDIES_METABOLITES, '','PRODUCT,TITLE', text)
                .then(response => {
                    if (isNotUndefined(response) && response.length === 1){
                        setData(response[0]);
                        setIsShow(true);
                    }
                }).catch(error => {
                setError(error);
                setIsShow(true);
            });
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive) {
            _search(event.detail);
        }
    }

    const searchByStudyMetabolite =  (event: { detail: any }) => {
        if (isActive) {
            setTitle(event.detail.COMPOUND_NAME);
            setData(event.detail);
            setIsShow(true);
            const inchikey = event.detail.INCHIKEY || event.detail.inchikey;
            if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
        }
    }

    const searchByCompoundEvent =  (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc, STUDIES_METABOLITES, event.detail, true, 'COMPOUND_NAME' );
        }
    }

    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_STUDY_METABOLITE, searchByStudyMetabolite, UUID);
        Events.on(EventsType.SEARCH_BY_METABOLITE, searchByCompoundEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_STUDY_METABOLITE, UUID);
            Events.off(EventsType.SEARCH_BY_METABOLITE, UUID);

        };
    }, [isActive]);

    // RENDERS --------------


    return(
        <p>
            {renderFields(Metabolite, data)}

            { !hasReadOnlyRole && (
                <>
            <FormEditModal
                           DATATYPE={STUDIES_METABOLITES}
                           save={saveData}
                           data={data}
                           schema={Metabolite}/>

            <DisplayDeleteModal DATATYPE={STUDIES_METABOLITES}
                                deleteDoc={deleteData}
                                data={data}
                                schema={Metabolite}/>
                </>
            )}

            <AuditModal id={data._id} undo={undo}/>
        </p>
    );


};


const MetaboliteCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MetaboliteCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(MetaboliteCard);

