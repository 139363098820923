import React, {useEffect} from 'react';
import data from './changes.json';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "./types";
import {CardWithBaseComponent} from "./common/BaseCard";


const _ChangeLogCard: React.FC<iBaseInnerCardProps> = ({setIsShow, setData}) => {

    const UUID = '_ChangeLogCard';

    useEffect(() => {
        setIsShow(true);
        setData(data);
    },[]);


    return (
        <>
                {data.map(({ version, date, author, items }, index) => (
                    <div key={index} style={{ textAlign: 'left'}}>
                        <h3><b>Version:</b> {version}</h3>
                        <div><b>Date: </b>{date} | <b>Author:</b> {author}</div>
                        <ul>
                            {items.map((item, itemIndex) => (
                                <li key={itemIndex}>{item}</li>
                            ))}
                        </ul>
                    </div>
                ))}
        </>
    );
};
const ChangeLogCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger, showBasket, storageValue }) => {
    return <CardWithBaseComponent cardComponent={_ChangeLogCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  storageValue={storageValue}/>;
};


export default React.memo(ChangeLogCard);
