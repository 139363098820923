import React from 'react';
import { ChartPie } from './ChartPie';
import {ChartPieComponentProps} from "./types";

const productBuildLabels = (data: any[]): string[] => {
    return data.map((row) => {
        let label = "";

        if (row.NAME) {
            label += `${row.NAME}\n`;
        }
        if (row.LOD) {
            label += `LOD: ${row.LOD}, `;
        }
        if (row.LOQ) {
            label += `LOQ: ${row.LOQ}\n`;
        }
        if (row.NUMBER_OF_ITEMS_PER_REPLICATE) {
            label += `Items per Replicate: ${row.NUMBER_OF_ITEMS_PER_REPLICATE}\n`;
        }
        if (row.NUMBER_OF_REPLICATES) {
            label += `Number of Replicates: ${row.NUMBER_OF_REPLICATES}`;
        }

        return label.slice(0, -2); // Remove trailing comma after LOQ
    });
};


const ChartPieProduct: React.FC<ChartPieComponentProps> = ({ data }) => {

    const filteredItems = data.items.filter(item => item.HEADER === "List of Products Analysis Values");

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filteredItems.map((basketItem, index) => {
                const pieData = basketItem.DATA
                    .filter((item: any) => !isNaN(Number(item.AVERAGE)))
                    .map(item => ({
                        NAME: item.COMPOUND_NAME,
                        VALUE: Number(item.AVERAGE),
                        LOQ: item.LOQ,
                        LOD: item.LOD,
                        NUMBER_OF_ITEMS_PER_REPLICATE: item.NUMBER_OF_ITEMS_PER_REPLICATE,
                        NUMBER_OF_REPLICATES: item.NUMBER_OF_REPLICATES
                    }));

                if (pieData.length === 0) return null;

                return (
                    <div key={index} style={{marginBottom: '2rem', maxWidth: '250px', marginLeft: '50px'}}>
                        <h5>{`${basketItem.DATA[0]?.ANALYSIS_TYPE}`}</h5>
                        <h5>{`${basketItem.DATA[0]?.PRODUCT}`}</h5>
                        <h5>{`${basketItem.DATA[0]?.TEST_METHOD_CODE}`}</h5>
                        <h5>{`${basketItem.DATA[0]?.REPORT_PROJECT_ID}`}</h5>
                        <ChartPie data={pieData} buildLabels={productBuildLabels}/>
                        <h5>{`[ ${basketItem.DATA[0]?.UNIT} ]`}</h5>
                    </div>
                );
            })}
        </div>
    );
};

export default ChartPieProduct;
