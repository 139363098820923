import {DataApiCache} from '../api/Cache'

const baseURL = process.env.REACT_APP_PMI_API_BASE_URL;

export const PRODUCTS_ANALYSIS_METHODS = 'Products_Analysis_Methods';
export const PRODUCTS_ANALYSIS_REPORTS = 'Products_Analysis_Reports';
export const PRODUCT_ANALYSIS_VALUES = "Products_Analysis_Values";

const _api = new DataApiCache();

export const getObjectByField = async (type: string, key: string, value: string) => {
    try{
        const address = `${baseURL}/products/${type}/${key}/${value}`;
        const data = await _api.get(address);
        return data;
    }
    catch(ex){
        throw new Error(`Failed to get compound data for ${type} - ${key} - ${value}`);
    }
};


export const getObjectByDoc = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/products/${type}/`;
        const data  = await _api.post(address, doc);
        return data;
    }
    catch(ex){
        throw new Error(`Failed to get compound data for ${type} `);
    }
};

export const getObjectsByRegEx = async (type: string, key: string, value: string) => {
    try{
        const address = `${baseURL}/products/${type}/regex`;
        const data = await _api.post(address, {
            field: key,
            pattern: value
        });
        return data;
    }
    catch(ex){
        throw new Error(`Failed to get compound data for ${type} - ${key} - ${value}`);
    }
};

export const getByFilters = async (type: string, filters: any[]) => {
    try{
        if (filters.length > 0) {
            const address = `${baseURL}/products/${type}/byfilters/`;
            const data = await _api.post(address, {
                filters: filters
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get data compounds using filters:  ${filters} `);
    }
};


export const save = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/products/${type}/save`;
        const data  = await _api.post(address, doc);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save compound data for ${type} `);
    }
};

export const deleteDoc = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/products/${type}/delete/${doc._key}`;
        const data  = await _api.delete(address);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to delete compound data for ${type} `);
    }
};

export const saveBulk = async (type: string, docs: any[]) => {
    try{
        const address = `${baseURL}/products/${type}/save/bulk`;
        const data  = await _api.post(address, docs);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save compound data for ${type} `);
    }
};

