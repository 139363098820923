import React, {useEffect} from "react";
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import Events from "../../../events";
import {EventsType} from "../../../events-types";
import {Collapse, Divider, List, ListItem} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

import {getDataByField} from "../../../api/Pubchem"
import {isNotUndefined} from "../../../utils/string";
import PubchemId from "./PubchemId";

const _PubchemCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, isActive }) => {

    let debounceTimeout: number = 0;

    const UUID = '_PubchemCard'

    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    }


    // SEARCH ----

    function searchBy(key:string, value:string, field:string){
        setIsShow(false);
        getDataByField(key, value, field)
            .then(response => {
                if (isNotUndefined(response.data) && response.data.length > 0) {
                    setTitle( value);
                    setData(response.data[0]);
                    setIsShow(true);
                }
                else if (response.error){
                    setError(response.error);
                    setIsShow(true);
                }
            }).catch(error => {
            setError(error);
            setIsShow(true);
        });
    }

    const searchByPubChemCompoundEvent = (event: { detail: any }) => {
        if(isActive){
            setData(event.detail);
            setTitle(event.detail['cmpdname']);
            setIsShow(true);

            console.log(event.detail);
            clearTimeout(debounceTimeout);
        }

    }

    const searchByPubchemCIDEvent = (event: { detail: any }) => {
        if (isActive){
            clearTimeout(debounceTimeout); // Cancel any existing debounce timeout

            debounceTimeout = window.setTimeout(() => {
                if (data.cid !== event.detail && String(event.detail) !== '-') {
                    searchBy('compound', event.detail, 'cid');
                }
                debounceTimeout = 0; /* Reset debounce timeout after execution */}, 1000);
        }

    }

    const searchByInchiKeyEvent = (event: { detail: any }) => {
        if (isActive){
            searchBy('compound', event.detail, '*');
        }

    }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEND_PUBCHEM_COMPOUND, searchByPubChemCompoundEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PUBCHEM_CID, searchByPubchemCIDEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);

        return () => {
            Events.off(EventsType.SEND_PUBCHEM_COMPOUND, UUID);
            Events.off(EventsType.SEARCH_BY_PUBCHEM_CID, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);

        };
    }, [isActive]);

    // RENDER ----------------

    return (
        <>
            <PubchemId data={data} />
            <b>IUPAC NAME</b>: {data['iupacname']}<br/>
            <List component='nav' aria-labelledby='nested-list-subheader'>

                <ListItem onClick={handleClick} style={{background: 'cyan'}}>
                    SYNONYMS
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                    in={isOpen}
                    timeout='auto'
                    unmountOnExit
                >
                    <List component='li' disablePadding>
                        {(data['cmpdsynonym'] || '').split('|').map((s: string) => {
                            return (
                                <ListItem key={s}>
                                    {s}
                                </ListItem>
                            );
                        })}
                    </List>

                </Collapse>
                <Divider />
            </List>
            <br/>
            <b>SMILES</b>: {data['canonicalsmiles']}<br/>
            <b>INCHI</b>: {data['inchi']}<br/>
            <b>INCHIKEY</b>: {data['inchikey']}<br/>
            <b>MOLECULA FORMULA </b>: {data['mf']}<br/>

            <b>EXACT MASS</b>: {data['exactmass']}<br/>
            <b>MONO ISOTOPIC MASS</b>: {data['monoisotopicmass']}<br/>
            <b> MW </b>: {data['mw']}<br/>
            <b>XLOGP </b>: {data['xlogp']}<br/>

            <b>POLAR AREA </b>: {data['polararea']}<br/>
            <b>ROTBONDS </b>: {data['rotbonds']}<br/>

            <b>ANNOTATION: </b>{data['annotation']}<br/>

        </>
    );
};



const PubchemCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PubchemCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(PubchemCard);
