import axios, { AxiosRequestConfig } from "axios";
import {isNotUndefined} from "../utils/string";
import {getCurrentUserTokenId} from "../services/Cognito";
import Events from "../events";
import {EventsType} from "../events-types";

const $log: Console = console;

export class DataApiCache {
    private cache: { [key: string]: any } = {};

    private maxSize: number;

    constructor(maxSize: number = 50) {
        this.maxSize = maxSize;
    }

    private addToCache(key: string, data: any) {
        const cacheKeys = Object.keys(this.cache);

        if (cacheKeys.length >= this.maxSize) {
            const oldestKey = cacheKeys[0];
            delete this.cache[oldestKey];
        }

        this.cache[key] = data;
    }
    public clearCache() {
        this.cache = {}; // Reset the cache to an empty object
    }

    private async getHeaders(url: string): Promise<any>{
        const tokenId = await getCurrentUserTokenId();
        const config: AxiosRequestConfig = {
            headers: {  'Content-Type': 'application/json'},
        };

        if (url.startsWith('https://api.aer') && tokenId) {
            config.headers = {
                ...config.headers,
                Authorization: tokenId,
            };
        }

        return config;
    }


    async get_using_id_term(url:string, term: string){
        if (this.cache[term]) {
            return this.cache[term];
        } else {
            try {
                Events.trigger(EventsType.LOADING, url);
                const config = await this.getHeaders(url);
                const response = await axios.get(url, config);
                this.addToCache(term, response.data);

                if (process.env.REACT_APP_DEBUG) $log.debug(response.data);

                return response.data;
            } catch (error) {
                throw new Error(`Failed to fetch data from ${url}`);
            }
            finally{
                Events.trigger(EventsType.LOADED, url);
            }
        }
    }

    // Special function created because:
    // Pubchem sometimes return errors and we want to control to not cache empty values
    //
    async get_pubchem(url:string){
        if (this.cache[url]) {
            return this.cache[url];
        }
        else {
            try {
                Events.trigger(EventsType.LOADING, url);
                const config = await this.getHeaders(url);
                const response = await axios.get(url, config);

                if (isNotUndefined((response.data.Record)) ||
                    (isNotUndefined(response.data.SDQOutputSet[0].status.code) && response.data.SDQOutputSet[0].status.code == 0)) {
                    this.addToCache(url, response.data);
                }
                if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
                return response.data;
            } catch (error) {
                throw new Error(`Failed to fetch data from ${url}`);
            }
            finally{
                Events.trigger(EventsType.LOADED, url);
            }
        }
    }

    async get(url: string): Promise<any> {


        if (this.cache[url]) {
            return this.cache[url];
        } else {
            try {
                if (url.indexOf('taxonomy') === -1){
                    Events.trigger(EventsType.LOADING, url);
                }

                const config = await this.getHeaders(url);
                const response = await axios.get(url, config);
                this.addToCache(url, response.data);
                if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
                return response.data;
            } catch (error) {
                console.log(error)
                throw new Error(`Failed to fetch data from ${url} - ${error}`);
            }
            finally{
                if (url.indexOf('taxonomy') === -1){
                    Events.trigger(EventsType.LOADED, url);
                }
            }
        }
    }

    async post(url: string, payload: any): Promise<any> {

        const cacheKey = `${url}-${JSON.stringify(payload)}`;

        if (this.cache[cacheKey]) {
            return this.cache[cacheKey];
        } else {
            try {

                if (url.indexOf('taxonomy') === -1){
                    Events.trigger(EventsType.LOADING, url);
                }


                const config = await this.getHeaders(url);
                const response = await axios.post(url, payload, config);
                this.addToCache(cacheKey, response.data);
                if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
                return response.data;
            } catch (error) {
                throw new Error(`Failed to post data to ${url}`);
            }
            finally {
                if (url.indexOf('taxonomy') === -1){
                     Events.trigger(EventsType.LOADED, url);
                }
            }
        }
    }


    // @INFO: check if we can remove this and use the generic
    async save_post(url: string, payload: any): Promise<any> {

                const config = await this.getHeaders(url);
                const response = await axios.post(url, payload, config);
                return response.data;

    }


    async delete(url: string): Promise<any> {

            try {
                Events.trigger(EventsType.LOADING, url);
                const config = await this.getHeaders(url);
                const response = await axios.delete(url, config);
                if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
                return response.data;
            } catch (error) {
                throw new Error(`Failed to post data to ${url}`);
            }
            finally {
                Events.trigger(EventsType.LOADED, url);
            }
    }
}

export default DataApiCache;
