
export const defaultGenerateColors = (count: number) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
        const hue = (i * 137.5) % 360; // Generate different hues
        const color = `hsl(${hue}, 70%, 50%)`; // Convert hue to HSL color
        colors.push(color);
    }
    return colors;
};

export const defaultBuildLabels = (data: any[]): string[] => {
    return data.map((row) => {
        return `${row.NAME}`;
    });
};

