import React, {useEffect} from 'react';
import Events from '../../events';
import {EventsType} from '../../events-types';
import {COMPOUND, deleteDoc, getObjectByDoc, getObjectByField, getObjectsByRegEx, save} from '../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../types";
import {CardWithBaseComponent} from "../common/BaseCard";
import {isNotEmpty, isNotUndefined, isValidCASNumber, isValidIchiKey} from "../../utils/string";
import {Compound} from "./types";
import {renderFields} from "../../utils/pair_entry";
import FormEditModal from '../common/FormEditModal';
import AuditModal from "../common/AuditModal";
import DisplayDeleteModal from "../common/DeleteConfirmationModal";
import {useRoles} from "../../App.Context";

const _CompoundCard: React.FC<iBaseInnerCardProps> = ({ setIsShow,
                                                          setError,
                                                          setData,
                                                          data ,
                                                          isActive,
                                                          setTitle,
                                                          searchBy,
                                                          searchByDoc}) => {

    const UUID = '_CompoundCard'
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = (data: any) => {
        save(COMPOUND, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = (data: any) => {
        deleteDoc(COMPOUND, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = (undoData:any) => {
        Object.assign(data, undoData);
        save(COMPOUND, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH functions ----------------------


    const searchEvent = (event: { detail: string }) => {

        if (!isActive) return;

        if (isValidCASNumber(event.detail)) {
            searchByCASEvent(event);
        } else if (isValidIchiKey(event.detail)) {
            searchByInchiKeyEvent(event);
        } else {
           searchByTextEvent(event);
        }
    };

    const searchByPubchemIDEvent = (event: { detail: string }) => {

        if (!isActive) return;

        setIsShow(false);

        getObjectByField(COMPOUND,  'PUBCHEM_CID', event.detail)
            .then(response => {
                if (isNotUndefined(response) && isNotUndefined(response[0])) {
                    setData(response[0]);
                    setIsShow(true);
                    setTitle('PUBCHEM_CID:' + event.detail);
                }
            }).catch(ex => {
            const error =
                ex.response.status === 404
                    ? "Resource Not found"
                    : "An unexpected error has occurred";
            setError(error);
        });
    }

    const searchByCASEvent = async (event: { detail: string }) => {

        if (!isActive) return;

        const result = await searchBy?.(getObjectsByRegEx, COMPOUND, 'CAS', '^' + event.detail + '$');
        const inchikey = result?.INCHIKEY;
        if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
    }

    const searchByTextEvent = async (event: { detail: string }) => {

        if (!isActive) return;

        const result : any = searchBy?.(getObjectsByRegEx, COMPOUND, 'COMPOUND_NAME,SYNONYMS', event.detail);
        const inchikey = result?.INCHIKEY;
        if (isNotUndefined(inchikey)) Events.trigger(EventsType.SEARCH_BY_INCHIKEY, inchikey);
    }

    const searchByInchiKeyEvent = (event: { detail: string }) => {

        if (!isActive) return;

        searchBy?.(getObjectsByRegEx, COMPOUND, 'INCHIKEY',  '^' + event.detail + '$'); //remove regex from inchikey search
    }

    const searchByCompoundEvent = (event: { detail: any }) => {

        if (!isActive) return;

        searchByDoc?.(getObjectByDoc, COMPOUND, event.detail, true, 'COMPOUND_NAME' );

        if (event.detail.CBD_ID) {
            Events.trigger(EventsType.SEARCH_BY_CBD_ID, event.detail.CBD_ID);
        }
    }

    // EVENTS --------------

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchByCASEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        Events.on(EventsType.SEARCH_BY_COMPOUND, searchByCompoundEvent, UUID);
        Events.on(EventsType.SEARCH_BY_PUBCHEM_CID, searchByPubchemIDEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_BY_COMPOUND, UUID);
            Events.off(EventsType.SEARCH_BY_PUBCHEM_CID, UUID);

        };
    }, [isActive]);


    // RENDER ----------------



    return(
        <>
            {renderFields(Compound, data)}

            { !hasReadOnlyRole && (
            <>
                <FormEditModal DATATYPE={COMPOUND}
                               save={saveData}
                               data={data}
                               schema={Compound}/>

                <DisplayDeleteModal DATATYPE={COMPOUND}
                                    deleteDoc={deleteData}
                                    data={data}
                                    schema={Compound}/>
            </>
            )}

            <AuditModal id={data._id} undo={undo}/>

        </>
    );

};


const CompoundCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent
                                        cardComponent={_CompoundCard}
                                        header={header}
                                        width={width}
                                        eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(CompoundCard);


