import React, {useEffect} from 'react';

import {Box, IconButton} from "@mui/material";
import {Visibility as VisibilityIcon} from "@mui/icons-material";
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

import MaterialReactTable from 'material-react-table';

import Events from '../../events';
import { EventsType } from '../../events-types';

import {getObjectsByRegEx, getByFilters, COMPOUND} from '../../api/Compounds';
import { iBaseCardWithComponentProps, iBaseInnerCardProps} from "../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../common/BaseCard";
import {Compound} from "./types";
import {getColumns, getInitialColumnState} from "../../utils/tables";
import {isValidCASNumber, isValidIchiKey} from "../../utils/string";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../utils/export_xlsx";



const _CompoundsTable: React.FC<iBaseInnerCardProps> = ({ data ,
                                                           setColumnFilters, columnFilters,
                                                           // setTextSearch, textSearch,
                                                           isActive,
                                                           searchListBy, searchListByFilters
                                                          }) => {

    const UUID = '_CompoundsTable'

    const columnNames = Object.keys(Compound);
    const columnsToView = ['COMPOUND_NAME','INCHIKEY','SMILES','CAS'];

    const columns = getColumns(Compound);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);


    // SEARCH -------------

    function _search(text: string) {
        if (text.length > 0){
            if (isValidCASNumber(text)){
                searchListBy?.(getObjectsByRegEx, COMPOUND, 'CAS', text) ;
            } else if (isValidIchiKey(text)) {
                searchListBy?.(getObjectsByRegEx, COMPOUND, 'INCHIKEY',text);
            } else {
                searchListBy?.(getObjectsByRegEx, COMPOUND, 'COMPOUND_NAME,SYNONYMS', text);
            }
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === COMPOUND){
            searchListByFilters?.(getByFilters, COMPOUND, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ---------------

    useEffect(() => {

        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);


    // RENDER ----------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (

                            <MaterialReactTable
                                columns={columns}
                                data={data?? []}
                                enableRowSelection //enable some features
                                enableColumnOrdering
                                enableGlobalFilter={false} //turn off a feature
                                enableDensityToggle={true}
                                initialState={{
                                    columnVisibility: _initial_columns_state,
                                    density: 'compact',
                                }}

                                /* SAVE FILTERS   */

                                onColumnFiltersChange={handleFilterFnChange}
                                state={{ columnFilters }}


                                /* ROW ACTION   */

                                enableRowActions={true}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                        <IconButton
                                            size="small"
                                            color="secondary"
                                            onClick={() => {
                                                Events.trigger(EventsType.SEARCH_BY_COMPOUND, row.original);
                                            }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>

                                    </Box>
                                )}
                                positionToolbarAlertBanner="bottom"
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => exportToExcel(data, "Compounds")}
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export All Data
                                        </Button>
                                        <Button
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Compounds")}

                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export Selected Rows
                                        </Button>

                                    </Box>
                                )}
                            />
    );
};

const CompoundsTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_CompoundsTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(CompoundsTable);
