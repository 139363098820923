import React, { useState, useEffect } from 'react';
import {
    signOut,
    getCurrentUserNameAndRoles
} from "../services/Cognito";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Box, Divider} from '@mui/material';


const User = () => {

    const [user, setUser] = useState<string | null>('');
    const [roles, setRoles] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        const fetchData = async () => {
            const { user, roles } = await getCurrentUserNameAndRoles();
            setUser(user);
            setRoles(roles);
        };
        fetchData();
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box sx={{ display: 'inline-flex' }}>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{ color: 'white' , fontSize: 'medium' }}
            >
                {user}
            </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-center'
                        },
                    }}
                >
                    {roles.map((role, index) => (
                        <MenuItem key={index} style={{ fontWeight: 'bold', color: 'green' }}>{role}</MenuItem>
                    ))}
                    <div style={{ borderBottom: '5px solid green', width: '100%', margin: '5px 0' }}></div>
                    <MenuItem onClick={signOut}>Logout</MenuItem>
                </Menu>
        </Box>
    )
}


export default User;
