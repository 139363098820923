import '../utils/arrays';
import DataApiCache from "./Cache";
import { xml2js } from 'xml-js';

const URL_CANNABIS_ID = process.env.REACT_APP_PROXY_API_BASE_URL + "/cannabisdatabase/compounds/_#CBD_ID#_.xml";

const _api = new DataApiCache();

function innerArray(json:any){

    const parsed: any = Array.isArray(json) ? [] : {};

    for (const key in json) {
        let value = json[key];
        let inner_objects: any = {}
            for (const inner_key in value){
                let inner_value = value[inner_key];
                if (typeof value === 'object') {
                    inner_value = inner_value['_text'];
                }
                if (inner_value !== undefined && inner_value !== '') {
                    inner_objects[inner_key] = inner_value;
                }
            }
        parsed.push(inner_objects);
    }

    return parsed.filter((obj:any) => Object.keys(obj).length !== 0);

}

function parseFields(json: any): any {
    if (typeof json !== 'object' || json === null) {
        return json;
    }

    const parsed: any = Array.isArray(json) ? [] : {};

    for (const key in json) {
        if (typeof json[key] === 'object' && '_text' in json[key]) {
            const value = json[key]._text;
            if (value !== '') {
                parsed[key] = value;
            }
        }
        // else if (key === 'general_references') {
        //     parsed[key] = innerArray(json[key].reference);
        // }else if (key === 'predicted_properties' ) {
        //     parsed[key] = innerArray(json[key].property);
        // }else if (key === 'experimental_properties'){
        //     parsed[key] = innerArray(json[key].property);
        // }else if (key === 'synonyms') {
        //     parsed[key] = innerArray(json[key].synonym);
        // }
        // missing taxonomy
    }

    return parsed;
}




export const getCannabis = async (cbd_id: string) => {

    const address = URL_CANNABIS_ID.replace('_#CBD_ID#_', cbd_id);

    let data:any = xml2js(await _api.get(address), { compact: true});
    data = parseFields(data.compound);

    return data;
}


