import React, { useState } from 'react';
import { ChartPie } from './ChartPie';
import { ChartPieComponentProps } from './types';

const ChartPiePKIndices: React.FC<ChartPieComponentProps> = ({ data }) => {
    const fieldOptions = [
        { key: 'DOSE_MG_KG', label: 'Dose in mg/kg (DOSE_MG_KG)' },
        { key: 'BODYWEIGHT_KG', label: 'Bodyweight in kg (BODYWEIGHT_KG)' },
        { key: 'DOSE_MG', label: 'Dose in Milligrams (DOSE_MG)' },
        { key: 'TMAX_H', label: 'Time to Maximum Concentration (TMAX_H)' },
        { key: 'CMAX_NG_ML', label: 'Concentration in ng/mL (CMAX_NG_ML)' },
        { key: 'HALF_LIFE_H', label: 'Half-Life in Hours (HALF_LIFE_H)' },
        { key: 'AUC_NG_H_ML', label: 'Area Under Curve ng·h/mL (AUC_NG_H_ML)' },
        { key: 'CMAX_DOSEN', label: 'Maximum Dose Concentration (CMAX_DOSEN)' },
        { key: 'AUC_DOSEN', label: 'Area Under Curve DOSEN (AUC_DOSEN)' },
        { key: 'CMAX_DOSE', label: 'Maximum Concentration per Dose (CMAX_DOSE)' },
        { key: 'AUC_DOSE', label: 'Area Under Curve per Dose (AUC_DOSE)' },
        { key: 'CMAX_TMAX', label: 'Maximum Concentration at TMAX (CMAX_TMAX)' },
    ];

    const sortedFieldOptions = fieldOptions.sort((a, b) => a.label.localeCompare(b.label));
    const [selectedField, setSelectedField] = useState(sortedFieldOptions[0].key);
    const selectedFieldLabel = sortedFieldOptions.find(option => option.key === selectedField)?.label;

    const filteredItems = data.items.filter(item => item.HEADER.startsWith('PK Indices'));

    const handleFieldChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedField(event.target.value);
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ width: '100%', marginBottom: '20px' }}>
                {/*<label htmlFor="fieldSelect">Select Field: </label>*/}
                <select id="fieldSelect" value={selectedField} onChange={handleFieldChange}>
                    {sortedFieldOptions.map((field, index) => (
                        <option key={index} value={field.key}>
                            {field.label}
                        </option>
                    ))}
                </select>
            </div>

            {filteredItems.map((basketItem, index) => {
                const pieData = basketItem.DATA
                    .filter((item: any) => !isNaN(Number(item[selectedField])))
                    .map(item => ({
                        NAME: item.PRODUCT,
                        VALUE: Number(item[selectedField])
                    }));

                if (pieData.length === 0) return null;

                return (
                    <div key={index} style={{marginBottom: '2rem', maxWidth: '250px', marginLeft: '50px'}}>
                        <h5>{`${basketItem.HEADER} ${basketItem.TITLE}`}</h5>
                        <p>{`${selectedFieldLabel}`}</p>
                        <ChartPie data={pieData}/>
                    </div>
                );
            })}
        </div>
    );
};

export default ChartPiePKIndices;
