import {DataApiCache} from '../api/Cache'
import {isNotEmpty} from "../utils/string";

const baseURL = process.env.REACT_APP_PMI_API_BASE_URL;

export const STUDIES_CBD = 'CBD_Studies';
export const SC_THERAPEUTIC_INDICATIONS = 'Therapeutic_Indicators';
export const SC_PK = 'PK';
export const STUDIES_METABOLITES = 'Metabolites';
export const CBD_PK_INDICES = 'CBD_PK_Indices';

const _api = new DataApiCache();

export const getStudies = async (type: string, study_class: string, key: string, value: string) => {
    try{
        const address = baseURL +"/studies/" + type + "/" + key + "/" + value;
        const data = await _api.get(address);
        return data;
    }
    catch(ex){
        throw new Error('... cannot get data');
    }
};

export const getObjectByDoc = async (type: string, doc: any) => {
    try{
        const address = baseURL +"/studies/" + type + "/";
        const data = await _api.post(address, doc);
        return data;
    }
    catch(ex){
        throw new Error('... cannot get data');
    }
};

export const getStudiesRegEx = async (type: string,  study_class: string, key: string, value: string) => {
    try{
        const address = baseURL +"/studies/" + type + "/regex";
        let payload: { field: string, pattern: string, study_class?: string } = {
            field: key,
            pattern: value
        };

        if (isNotEmpty(study_class)) {
            payload.study_class = study_class;
        }

        const data = await _api.post(address, payload);
        return data;
    }
    catch(ex){
        throw new Error('... cannot get data');
    }
};

export const getByFilters = async (type: string, filters: any[]) => {
    try{
        if (filters.length > 0) {
            const address = `${baseURL}/studies/${type}/byfilters/`;
            const data = await _api.post(address, {
                filters: filters
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get data Studies using filters:  ${filters} `);
    }
};

export const save = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/studies/${type}/save`;
        const data  = await _api.post(address, doc);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save compound data for ${type} `);
    }
};

export const deleteDoc = async (type: string, doc: any) => {
    try{
        const address = `${baseURL}/studies/${type}/delete/${doc._key}`;
        const data  = await _api.delete(address);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to delete compound data for ${type} `);
    }
};

export const saveBulk = async (type: string, docs: any[]) => {
    try{
        const address = `${baseURL}/studies/${type}/save/bulk`;
        const data  = await _api.post(address, docs);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save bulk data for ${type} `);
    }
};



