import React, { useEffect, useState } from 'react';
import { iBaseCardWithComponentProps, iBaseInnerCardProps } from "./types";
import { CardWithBaseComponent } from "./common/BaseCard";
import { getStadistics } from "../api/Audits";
import {formatNumberWithDecimalsAndSeparation} from "../utils/string";

const _StadisticsCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setData, data }) => {

    type TranslationKey = keyof typeof translations; // Define a type for possible keys

    const translations = {
        "Compounds": "Total Compounds",
        "Cannabinoids_Unique": "Total Cannabinoids",
        "Metabolites_Unique": "Total Metabolites",
        "Terpenes_Unique": "Total Terpenes",
        "Varieties_Unique": "Total Varieties",
        "CBD_Studies": "Total Studies",
        "CBD_Market": "Total Products on Market",
        "References": "Total References",
        "Cannabinoids": "Total References of Cannabinoids",
        "Metabolites": "Total Doses of Metabolites",
        "Terpenes": "Total References of Terpenes",
        "Varieties": "Total References of Varieties",
        "V_Concentrations": "Total Compounds Concentrations on Varieties",
        "Products_Analysis_Methods": "Total Product Analysis Methods",
        "Products_Analysis_Reports": "Total Reports of Analyzed Products",
        "Products_Analysis_Unique": "Total diff Compounds Analyzed",
        "Products_Analysis_Values": "Total Concentrations of Compounds in Products",
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsShow(true);
            const fetchedData = await getStadistics();
            setData(fetchedData);
        };

        fetchData();
    }, []);

    return (
        <>
            {data.map((statistic: Record<TranslationKey, number>) => {
                const key = Object.keys(statistic)[0] as TranslationKey;
                return (
                    <div key={key}>
                        <b>{translations[key]} : </b> <span style={{fontSize:'large'}}>{formatNumberWithDecimalsAndSeparation(Object.values(statistic)[0], 0)}</span>
                    </div>
                );
            })}
        </>
    );
};

const StadisticsCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                         header,
                                                                                         width,
                                                                                         eventGroupToTrigger,
                                                                                         showBasket,
                                                                                         storageValue
                                                                                     }) => {
    const [data, setData] = useState<any[]>([]);

    return <CardWithBaseComponent cardComponent={_StadisticsCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  storageValue={storageValue}/>;
};

export default React.memo(StadisticsCard);
