export class BidirectionalDictionary<TKey extends string, TValue extends string> {
    private dict: Map<TKey, TValue> = new Map();
    private reverseDict: Map<TValue, TKey> = new Map();

    constructor(entries?: [TKey, TValue][]) {
        if (entries) {
            entries.forEach(([key, value]) => {
                this.set(key, value);
            });
        }
    }

    set(key: TKey, value: TValue): void {
        this.dict.set(key, value);
        this.reverseDict.set(value, key);
    }

    get(prefix: string): { key: TKey, value: TValue } | undefined {
        let longestEntry: { key: TKey, value: TValue } | undefined = undefined;

        this.dict.forEach((value, key) => {
            if (prefix.startsWith(key)) {
                if (!longestEntry || key.length > longestEntry.key.length) {
                    longestEntry = { key, value };
                }
            }
        });

        this.reverseDict.forEach((key, value) => {
            if (prefix.startsWith(value)) {
                if (!longestEntry || value.length > longestEntry.value.length) {
                    longestEntry = { key, value };
                }
            }
        });

        return longestEntry;
    }



    getKey(value: TValue): TKey | undefined {
        return this.reverseDict.get(value);
    }

    getValue(key: TKey): TValue | undefined {
        return this.dict.get(key);
    }

    hasKey(key: TKey): boolean {
        return this.dict.has(key);
    }

    hasValue(value: TValue): boolean {
        return this.reverseDict.has(value);
    }

    delete(prefix: string): void {
        let longestEntry: TKey | TValue | undefined = undefined;

        this.dict.forEach((value, key) => {
            if (key.startsWith(prefix)) {
                if (!longestEntry || key.length > (longestEntry as string).length) {
                    longestEntry = key;
                }
            }
        });

        this.reverseDict.forEach((key, value) => {
            if (value.startsWith(prefix)) {
                if (!longestEntry || value.length > (longestEntry as string).length) {
                    longestEntry = value;
                }
            }
        });

        if (longestEntry !== undefined) {
            this.dict.delete(longestEntry as TKey);
            this.reverseDict.delete(longestEntry as TValue);
        }
    }

}

// Example usage:
//
// const colorDict = new BidirectionalDictionary<string, string>([
//     ['red', 'FF0000'],
//     ['green', '00FF00'],
//     ['blue', '0000FF'],
// ]);
//
// console.log(colorDict.get('red')); // Output: 'FF0000'
// console.log(colorDict.getKey('00FF00')); // Output: 'green'
//
// colorDict.set('yellow', 'FFFF00');
// console.log(colorDict.get('yellow')); // Output: 'FFFF00'
// console.log(colorDict.getKey('FFFF00')); // Output: 'yellow'
//
// colorDict.delete('g'); // Delete entries with keys or values starting with 'g'
// console.log(colorDict.get('green')); // Output: undefined
// console.log(colorDict.getKey('00FF00')); // Output: undefined
