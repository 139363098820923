import {DataApiCache} from '../api/Cache'

const baseURL = process.env.REACT_APP_PMI_API_BASE_URL;

const _api = new DataApiCache();

export const getObjectByDoc = async (doc: any) => {
    try{
        const address = `${baseURL}/baskets/`;
        const data  = await _api.post(address, doc);
        return data;
    }
    catch(ex){
        throw new Error(`Failed to get data for Baskets `);
    }
};

export const getObjectsByRegEx = async (key: string, value: string) => {
    try{
        const address = `${baseURL}/baskets/regex`;
        const data = await _api.post(address, {
            field: key,
            pattern: value
        });
        return data;
    }
    catch(ex){
        throw new Error(`Failed to get basket data for - ${key} - ${value}`);
    }
};

export const getByFilters = async (filters: any[]) => {
    try{
        if (filters.length > 0) {
            const address = `${baseURL}/baskets/byfilters/`;
            const data = await _api.post(address, {
                filters: filters
            });
            return data;
        }
        else return [];
    }
    catch(ex:any){
        throw new Error(`Failed to get basket data using filters:  ${filters} `);
    }
};


export const save = async (doc: any) => {
    try{
        const address = `${baseURL}/baskets/save`;
        const data  = await _api.post(address, doc);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to save basket data for `);
    }
};

export const deleteDoc = async (doc: any) => {
    try{
        const address = `${baseURL}/baskets/delete/${doc._key}`;
        const data  = await _api.delete(address);
        _api.clearCache();
        return data;
    }
    catch(ex){
        throw new Error(`Failed to delete basket data for `);
    }
};

