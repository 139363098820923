import React, {useEffect} from 'react';
import Events from '../../../events';
import { EventsType } from '../../../events-types';
import {getObjectsByRegEx, save, deleteDoc, MARKET_CBD, getObjectByDoc} from '../../../api/Compounds';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../../types";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {Market_CBD} from "./types";
import { renderFields} from "../../../utils/pair_entry";
import FormEditModal from "../../common/FormEditModal";
import AuditModal from "../../common/AuditModal";
import DisplayDeleteModal from "../../common/DeleteConfirmationModal";
import {isNotEmpty} from "../../../utils/string";
import {useRoles} from "../../../App.Context";

const _MarketCBDCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data , setTitle, searchBy, searchByDoc, isActive}) => {

    const UUID = '_MarketCBDCard';
    const roles = useRoles();
    const hasReadOnlyRole = roles.includes("ReadOnly");

    // MODAL EDIT CALLBACK

    const saveData = async (data: any) => {
        save(MARKET_CBD, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const deleteData = async (data: any) => {
        deleteDoc(MARKET_CBD, data).then((res) => {
            setData({});
            setIsShow(false);
        }).catch(setError);
    }

    const undo = async (undoData:any) => {
        Object.assign(data, undoData);
        save(MARKET_CBD, data).then((res) => {
            setData(res);
            setIsShow(true);
        }).catch(setError);
    }

    const createEvent = (event: { detail: any }) => {
        if (isNotEmpty(event.detail)) {
            delete event.detail._id;
            saveData(event.detail);
        }
    };

    // SEARCH -----------------

    const searchEvent = (event: { detail: string }) => {
        if (isActive) {
            searchBy?.(getObjectsByRegEx,MARKET_CBD,'DRUG_NAME,ACTIVE_INGREDIENT,THERAPEUTICAL_INDICATION', event.detail);
        }
    }

    const searchByCBDDrugEvent = (event: { detail: any }) => {
        if (isActive){
            setTitle(event.detail.DRUG_NAME);
            setData(event.detail);
            setIsShow(true);
        }

    }

    const searchByCBDMarketDrugEvent = (event: { detail: any }) => {
        if (isActive) {
            searchByDoc?.(getObjectByDoc, MARKET_CBD,  event.detail, false, 'DRUG_NAME' );
        }
    }

    // ----- EVENTS

    useEffect(() => {
        Events.on(EventsType.CREATE, createEvent, UUID);
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CBD_DRUG, searchByCBDDrugEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CBD_MARKET_DRUG, searchByCBDMarketDrugEvent, UUID);

        return () => {
            Events.off(EventsType.CREATE, UUID);
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CBD_DRUG, UUID);
            Events.off(EventsType.SEARCH_BY_CBD_MARKET_DRUG, UUID);

        };
    }, [isActive]);

    // RENDERS --------------


    return(
        <p>
            {renderFields(Market_CBD, data)}

            { !hasReadOnlyRole && (
                <>
                    <FormEditModal
                        save={saveData}
                        DATATYPE={MARKET_CBD}
                        data={data}
                        schema={Market_CBD}/>

                    <DisplayDeleteModal DATATYPE={MARKET_CBD}
                                        deleteDoc={deleteData}
                                        data={data}
                                        schema={Market_CBD}/>

                </>
            )}


            <AuditModal id={data._id} undo={undo}/>


        </p>
    );

};


const MarketCBDCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width,eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MarketCBDCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(MarketCBDCard);
