import React from "react";

const WizardSearchStep1: React.FC<{
    onSelect: (selectedKey: string) => void;
    onNext: () => void;
    data: Record<string, any>;
}> = ({
          onSelect,
          onNext,
          data,
      }) => {
    const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedKey = e.target.value;
        onSelect(selectedKey);
        onNext();
    };

    return (
        <div>
            <br/>
            <h4>Step 1: Choose a Data Type</h4>
            <select onChange={handleSelect}>
                <option>Select an object</option>
                {Object.keys(data).map((key, index) => (
                    <option key={index} value={key}>
                        {key}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default WizardSearchStep1;
