import React from 'react';
import { ChartPie } from './ChartPie';
import {ChartPieComponentProps} from "./types";

const ChartPieTerepenesConcentrations: React.FC<ChartPieComponentProps> = ({ data }) => {

    const filteredItems = data.items.filter(item => item.HEADER.startsWith("Concentration of terpenes"));

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filteredItems.map((basketItem, index) => {
                const pieData = basketItem.DATA
                    .filter((item: any) => !isNaN(Number(item.CONCENTRATION)))
                    .map(item => ({
                        NAME: item.NAME,
                        VALUE: Number(item.CONCENTRATION)
                    }));

                if (pieData.length === 0) return null;

                return (
                    <div key={index} style={{marginBottom: '2rem', maxWidth: '250px', marginLeft: '50px'}}>
                        <h5>{`${basketItem.HEADER} ${basketItem.TITLE}`}</h5>
                        <ChartPie data={pieData}/>
                        <h5>{`[ % dry weight ]`}</h5>
                    </div>
                );
            })}
        </div>
    );
};

export default ChartPieTerepenesConcentrations;
