import axios, { AxiosRequestConfig } from "axios";
import { getCurrentUserTokenId } from "../services/Cognito";
import Events from "../events";
import { EventsType } from "../events-types";
import {isNotUndefined} from "../utils/string";

const baseURL = process.env.REACT_APP_PMI_API_BASE_URL + '/audits';

const $log: Console = console;

async function getHeaders(): Promise<AxiosRequestConfig> {
    const tokenId = await getCurrentUserTokenId();
    const config: AxiosRequestConfig = {
        headers: { 'Authorization': `Bearer ${tokenId}`, 'Content-Type': 'application/json' },
    };

    return config;
}

async function get(url: string): Promise<any> {
    try {
        Events.trigger(EventsType.LOADING, {});
        const config = await getHeaders();
        const response = await axios.get(url, config);

        if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
        return response.data;
    } catch (error) {
        throw new Error(`Failed to post data to ${url}`);
    } finally {
        Events.trigger(EventsType.LOADED, {});
    }
}


async function post(url: string, payload: any): Promise<any> {
    try {
        Events.trigger(EventsType.LOADING, {});
        const config = await getHeaders();
        const response = await axios.post(url, payload, config);

        if (process.env.REACT_APP_DEBUG) $log.debug(response.data);
        return response.data;
    } catch (error) {
        throw new Error(`Failed to post data to ${url}`);
    } finally {
        Events.trigger(EventsType.LOADED, {});
    }
}

export const getAudit = async ( id: string ) => {
    try {
        const address = `${baseURL}/audit/`;
        const data = await post(address, { id: id });
        return data;
    } catch (ex) {
        throw new Error(`Failed to get Audit data for ${id}`);
    }
};

export const getAudits = async ( days: number , reason?: string )=> {
    try {
        let address = `${baseURL}/audit/changes/${days}`;

        if (isNotUndefined(reason)) {
            address = address + `/${reason}`;
        }

        const data = await get(address);
        return data;
    } catch (ex) {
        throw new Error(`Failed to get Audits from ${days} days and reson = [${reason}] `);
    }
}

export const getAuditsBetweenDates = async ( from: string , to: string, reason?: string )=> {
    try {
        let address = `${baseURL}/audit/between/`;
        const payload = {
            from: from,
            to: to,
            reason: reason || 'modified'
        }
        const data = await post(address, payload);
        return data;
    } catch (ex) {
        throw new Error(`Failed to get Audits from ${from} to ${to} and reason = [${reason}] `);
    }
}

export const getStadistics = async ()=> {
    try {
        let address = `${baseURL}/audit/statistics`;

        const data = await get(address);
        return data;
    } catch (ex) {
        $log.error(ex);
        throw new Error(`Failed to get Stadistics from database `);
    }
}
