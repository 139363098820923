import React, {useEffect} from 'react';

import { Box } from '@mui/material';
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

import MaterialReactTable from 'material-react-table';

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {CANNABINOIDS, getObjectsByRegEx, getByFilters} from '../../../api/Compounds';
import {Cannabinoid} from './types';
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from '../../types';


import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";

const _CannabinoidTable: React.FC<iBaseInnerCardProps> = ({  data,
                                                              isActive,
                                                              setColumnFilters, columnFilters,
                                                              searchListByFilters,
                                                              searchListBy
                                                             }) => {
    const UUID = 'CannabinoidTable';

    const columnNames = Object.keys(Cannabinoid);
    const columnsToView = ['COMPOUND_NAME','FAMILY_TYPE_NAME',
                            'FAMILY_TYPE_CODE','ABBREVIATION',
                            'ISOLATED','ISOLATED_FROM','STRUCTURE_ELUCIDATION'];

    const columns = getColumns(Cannabinoid);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    // SEARCH ------

    function _search(text: string) {
        searchListBy?.(getObjectsByRegEx,CANNABINOIDS,'COMPOUND_NAME,FAMILY_TYPE_NAME,FAMILY_TYPE_CODE', text);
    }

    const searchEvent = (event: { detail: string }) => {
        if(isActive){
            _search(event.detail);
        }
    }

    const searchByInchiKeyEvent = (event: { detail: string }) => {
        if(isActive) {
            searchListBy?.(getObjectsByRegEx, CANNABINOIDS, 'INCHIKEY', event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === CANNABINOIDS){
            searchListByFilters?.(getByFilters, CANNABINOIDS, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_INCHIKEY, searchByInchiKeyEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_INCHIKEY, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER -------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
                            <MaterialReactTable
                                columns={columns}
                                data={data?? []}
                                enableRowSelection //enable some features
                                enableColumnOrdering
                                enableGlobalFilter={false} //turn off a feature
                                enableDensityToggle={true}
                                initialState={{
                                    columnVisibility: _initial_columns_state,
                                    density: 'compact',
                                }}
                                /* SAVE FILTERS   */

                                onColumnFiltersChange={handleFilterFnChange}
                                state={{ columnFilters }}

                                /* ACTIONS */

                                enableRowActions={true}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                        <Button variant="contained" size="small"
                                                color="secondary"
                                                onClick={() => {
                                                    Events.trigger(EventsType.SEARCH_BY_CBD_ID, row.original.CBD_ID);
                                                    Events.trigger(EventsType.SEARCH_BY_CANNABINOID, row.original);
                                                }}>
                                            {row.original.CBD_ID || 'N/A' }
                                        </Button>
                                    </Box>

                                )}

                                positionToolbarAlertBanner="bottom"
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => exportToExcel(data, "Cannabinoids")}
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export All Data
                                        </Button>
                                        <Button
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Cannabinoids")}
                                            startIcon={<FileDownloadIcon />}
                                            variant="contained"
                                        >
                                            Export Selected Rows
                                        </Button>
                                    </Box>
                                )}
                            />
    );
};


const CannabinoidTable: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_CannabinoidTable} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger} />;
};

export default React.memo(CannabinoidTable);
