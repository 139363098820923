import React from "react";


interface ModalHook {
    isOpen: boolean;
    modalData: any;
    handleOpenModal: (data: any) => void;
    handleCloseModal: () => void;
}

export function useModal(): ModalHook {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [modalData, setModalData] = React.useState<any>({});

    const handleOpenModal = (_data: any) => {
        setIsOpen(true);
        setModalData(_data);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setModalData({});
    };

    return {
        isOpen,
        modalData,
        handleOpenModal,
        handleCloseModal,
    };
}
