//
// Therapeuric Indicator
//
export interface iTherapIndicator {
    PRODUCT: string;
    LINK: string;
    TITLE: string;
    AUTHORS: string;
    YEAR: string;
    TRIAL_TYPE: string;
    DOSE: string;
    TREATMENT_LENGTH: string;
    FORMULATION: string;
    DELIVERY: string;
    CONDITION: string;
    NUMBER_OF_PATIENTS: string;
    MALE_FEMALE: string;
    AGE: string;
    MAIN_RESULT: string;
    SECONDARY_FINDINGS: string;
    PLASMA_LEVELS: string;
    WITHDRAWAL_RATES: string;
    OTHER_COMMENTS: string;
    STUDY_TYPE: string;
    GENDER_SPLIT: string;
    ANY_OTHER_COMMENTS: string;
}
export const TherapeuticIndicator: iTherapIndicator = {
    PRODUCT: '',
    LINK: '',
    TITLE: '',
    AUTHORS: '',
    YEAR: '',
    TRIAL_TYPE: '',
    DOSE: '',
    TREATMENT_LENGTH: '',
    FORMULATION: '',
    DELIVERY: '',
    CONDITION: '',
    NUMBER_OF_PATIENTS: '',
    MALE_FEMALE: '',
    AGE: '',
    MAIN_RESULT: '',
    SECONDARY_FINDINGS: '',
    PLASMA_LEVELS: '',
    WITHDRAWAL_RATES: '',
    OTHER_COMMENTS: '',
    STUDY_TYPE: '',
    GENDER_SPLIT: '',
    ANY_OTHER_COMMENTS: '',
}

//
// PK
//

export interface iPK {
    LINK : string;
    TITLE  : string;
    AUTHORS  : string;
    YEAR  : string;
    BRIEF_DESCRIPTION  : string;
    SUMMARY  : string;
    PRODUCT  : string;
    DOSE  : string;
    FORMULATION  : string;
    DELIVERY  : string;
    VOLUNTEERS_OR_PATIENTS  : string;
    NUMBER_OF_PATIENTS  : string;
    MALE_FEMALE  : string;
    AGE  : string;
    TMAX_CBD  : string;
    CMAX_CBD  : string;
    HALF_LIFE_CBD  : string;
    AUC  : string;
    ELIMINATION_RATE  : string;
    CLEARANCE  : string;
    VOLUME_OF_DISTRIBUTION  : string;
    BIOAVAILABILITY  : string;
    ANY_OTHER_TISSUES_MEASURED  : string;
    URINARY_LEVELS  : string;
    METABOLITES_MEASURED  : string;
    SIDE_EFFECTS  : string;
    OTHER_COMMENTS  : string;
    STUDY_TYPE  : string;
    SPECIES  : string;
    TMAX  : string;
    CMAX  : string;
    HALF_LIFE  : string;
    OTHER_TISSUES_MEASURED  : string;
    DIFFERENCES_IN_PK  : string;
}
export const PK : iPK = {
    LINK : '',
        TITLE  : '',
        AUTHORS  : '',
        YEAR  : '',
        BRIEF_DESCRIPTION  : '',
        SUMMARY  : '',
        PRODUCT  : '',
        DOSE  : '',
        FORMULATION  : '',
        DELIVERY  : '',
        VOLUNTEERS_OR_PATIENTS  : '',
        NUMBER_OF_PATIENTS  : '',
        MALE_FEMALE  : '',
        AGE  : '',
        TMAX_CBD  : '',
        CMAX_CBD  : '',
        HALF_LIFE_CBD  : '',
        AUC  : '',
        ELIMINATION_RATE  : '',
        CLEARANCE  : '',
        VOLUME_OF_DISTRIBUTION  : '',
        BIOAVAILABILITY  : '',
        ANY_OTHER_TISSUES_MEASURED  : '',
        URINARY_LEVELS  : '',
        METABOLITES_MEASURED  : '',
        SIDE_EFFECTS  : '',
        OTHER_COMMENTS  : '',
        STUDY_TYPE  : '',
        SPECIES  : '',
        TMAX  : '',
        CMAX  : '',
        HALF_LIFE  : '',
        OTHER_TISSUES_MEASURED  : '',
        DIFFERENCES_IN_PK  : '',
}

//
// Metabolites
//

export interface iMetabolites {
    INCHIKEY : string;
    COMPOUND_NAME : string;
    PUBCHEM_CID : string;
    SPECIES : string;
    BIOLOGICAL_SAMPLE : string;
    DOSE : string;
    DOSING_REGIMEN : string;
    ROUTE : string;
    CONCENTRATION_LEVEL : string;
    DETAILS : string;
    COMMENT : string;
    REFERENCE_ID : string;
}
export const Metabolite : iMetabolites  = {
    INCHIKEY : '',
    COMPOUND_NAME : '',
    PUBCHEM_CID : '',
    SPECIES : '',
    BIOLOGICAL_SAMPLE : '',
    DOSE : '',
    DOSING_REGIMEN : '',
    ROUTE : '',
    CONCENTRATION_LEVEL : '',
    DETAILS : '',
    COMMENT : '',
    REFERENCE_ID : '',
}


export interface iPK_Indices {
    LINK: string;
    TITLE: string;
    AUTHORS: string;
    YEAR: string;
    REFERENCE: string;
    DOI: string;
    PMID: string;
    SPECIES: string;
    ROUTE: string;
    ROUTES: string;
    DOSE_MG_KG: string;
    BODYWEIGHT_KG: string;
    SUBSPECIES: string;
    DOSE_MG: string;
    PRODUCT: string;
    FORMULATION: string;
    FORMULATION_CATEGORY: string;
    TMAX_H: string;
    CMAX_NG_ML: string;
    HALF_LIFE_H: string;
    AUC_NG_H_ML: string;
    AUCT: string;
    COMMENTS: string;
    CMAX_DOSEN: string;
    AUC_DOSEN: string;
    CMAX_DOSE: string;
    AUC_DOSE: string;
    CMAX_TMAX: string;
    BIOAVAILABILITY: string;
}

export const PK_Indices : iPK_Indices = {
    LINK: '',
    TITLE: '',
    AUTHORS: '',
    YEAR: '',
    REFERENCE: '',
    DOI: '',
    PMID: '',
    SPECIES: '',
    ROUTE: '',
    ROUTES: '',
    DOSE_MG_KG: '',
    BODYWEIGHT_KG: '',
    SUBSPECIES: '',
    DOSE_MG: '',
    PRODUCT: '',
    FORMULATION: '',
    FORMULATION_CATEGORY: '',
    TMAX_H: '',
    CMAX_NG_ML: '',
    HALF_LIFE_H: '',
    AUC_NG_H_ML: '',
    AUCT: '',
    COMMENTS: '',
    CMAX_DOSEN: '',
    AUC_DOSEN: '',
    CMAX_DOSE: '',
    AUC_DOSE: '',
    CMAX_TMAX: '',
    BIOAVAILABILITY: ''
}
