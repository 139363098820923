/**
 * Array extension
 *
 * @author : Antonio Castellon ; antonio.castellon@contracted.pmi.com
 *
 * Philip Morris R&D International - 2023
 *
 * extracted from : http://jsfiddle.net/jeffijoe/ZWMHX/1/
 **/
export{}

/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/

declare global {
    interface Array<T> {
        remove(elem: T): void;
        contains(elem: T): Array<T>;
        sortBy(prop: string, order?: "asc" | "desc"): Array<T>;
        removeDuplicates(): Array<T>;
    }
}

if (!Array.prototype.remove) {
    Array.prototype.remove = function<T>(item: T): void {
        const index = this.indexOf(item);
        if (index > -1) {
            this.splice(index, 1);
        }
    }
}

/*
 How to use it...

 function isPrime(element, index, array) {
     var start = 2;
     while (start <= Math.sqrt(element)) {
        if (element % start++ < 1) return false;
     }
     return (element > 1);
 }

 console.log( [4, 6, 8, 12].find(isPrime) ); // undefined, not found
 console.log( [4, 5, 8, 12].find(isPrime) ); // 5

 */
if (!Array.prototype.find) {
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            enumerable: false,
            configurable: true,
            writable: true,
            value: function (predicate: any) {
                if (this == null) {
                    throw new TypeError('Array.prototype.find called on null or undefined');
                }
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }
                const list = Object(this);
                const length = list.length >>> 0;
                const thisArg = arguments[1];
                let value;

                for (let i = 0; i < length; i++) {
                    if (i in list) {
                        value = list[i];
                        if (predicate.call(thisArg, value, i, list)) {
                            return value;
                        }
                    }
                }
                return undefined;
            }
        });
    }
}

/**
 * Ascending ordering by default
 * @param prop
 * @param reverse
 * @returns {Array}
 */
if (!Array.prototype.sortBy) {
    Array.prototype.sortBy = function<T>(prop: string, order: "asc" | "desc" = "asc"): Array<T> {
        return this.sort((a, b) => {
            if (order === "asc") {
                if (a[prop] < b[prop]) {
                    return -1;
                }
                if (a[prop] > b[prop]) {
                    return 1;
                }
                return 0;
            } else {
                if (a[prop] > b[prop]) {
                    return -1;
                }
                if (a[prop] < b[prop]) {
                    return 1;
                }
                return 0;
            }
        });
    }
}

if (!Array.prototype.removeDuplicates) {
    Array.prototype.removeDuplicates = function<T>(): Array<T> {
        const uniqueItems = new Set(this);
        return Array.from(uniqueItems);
    }
}



