import '../utils/arrays';
import DataApiCache from "./Cache";

const URL_CLINICAL_TRIALS = "https://clinicaltrials.gov/api/int/studies?query.term=_#TERM#_&agg.synonyms=true&" +
    "aggFilters=&from=0&limit=1000&fields=OverallStatus%2CHasResults%2CBriefTitle%2CCondition%2CInterventionType" +
    "%2CInterventionName%2CLocationFacility%2CLocationCity%2CLocationState%2CLocationCountry%2CLocationStatus%2CLocationZip" +
    "%2CLocationGeoPoint%2CLocationContactName%2CLocationContactRole%2CLocationContactPhone%2CLocationContactPhoneExt" +
    "%2CLocationContactEMail%2CCentralContactName%2CCentralContactRole%2CCentralContactPhone%2CCentralContactPhoneExt" +
    "%2CCentralContactEMail%2CGender%2CMinimumAge%2CMaximumAge%2CStdAge%2CNCTId%2CStudyType%2CLeadSponsorName%2CAcronym" +
    "%2CEnrollmentCount%2CStartDate%2CPrimaryCompletionDate%2CCompletionDate%2CStudyFirstPostDate%2CResultsFirstPostDate" +
    "%2CLastUpdatePostDate%2COrgStudyId%2CSecondaryId%2CPhase%2CLargeDocLabel%2CLargeDocFilename%2CPrimaryOutcomeMeasure" +
    "%2CSecondaryOutcomeMeasure%2CDesignAllocation%2CDesignInterventionModel%2CDesignMasking%2CDesignWhoMasked" +
    "%2CDesignPrimaryPurpose%2CDesignObservationalModel%2CDesignTimePerspective%2CLeadSponsorClass" +
    "%2CCollaboratorClass&columns=conditions%2Cinterventions%2Ccollaborators&highlight=true";


const URL_CLINICAL_TRIAL_ID = "https://clinicaltrials.gov/api/int/studies/download/_#NCT_ID#_?format=json"

const $log: Console = console;

const _api = new DataApiCache();

export const getListClinicalTrials = async ( term:string ) => {
    $log.debug('... ClinicalTrials :: getListClinicalTrials called : ' + term);

    const address = URL_CLINICAL_TRIALS
        .replace('_#TERM#_', term)
    ;
    const data = await _api.get_using_id_term(address, term); // await axios.get(address);

    return data;
}


export const getClinicalTrialByID = async  (id: string ) => {
    $log.debug('... ClinicalTrial :: getClinicalTrialByID called : ' + id);

    const address = URL_CLINICAL_TRIAL_ID
        .replace('_#NCT_ID#_', id)
    ;
    const data = await _api.get_using_id_term(address, id); // await axios.get(address);

    return data;


}

