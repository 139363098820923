import React, {useEffect} from 'react';

import {Box, IconButton} from "@mui/material";
import {Visibility as VisibilityIcon} from "@mui/icons-material";
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

import MaterialReactTable from 'material-react-table';

import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {getStudiesRegEx, getByFilters, CBD_PK_INDICES } from '../../../api/Studies';
import {PK_Indices} from "./types";
import {iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {isNotUndefined} from "../../../utils/string";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";

const _PKIndicesTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                        setData, data ,
                                                        setColumnFilters, columnFilters,
                                                        setTitle, setSubtitle,
                                                         searchListByFilters,
                                                        isActive
                                                        }) => {

    const UUID = '_PKIndicesTableCard';

    const columnNames = Object.keys(PK_Indices);
    const columnsToView = ['PRODUCT', 'TITLE',
                            'DOSE_MG_KG','FORMULATION','ROUTE','TMAX_H'];

    const columns = getColumns(PK_Indices);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    // SEARCH ---------------------

    function _search(text: string) {
        setIsShow(false);
        getStudiesRegEx(CBD_PK_INDICES, '','PRODUCT,TITLE', text)
            .then((response) => {

                if (isNotUndefined(response) && response.length > 1) {
                    setTitle(text);
                    setData(response);
                    setIsShow(true);
                }

            }).catch(error => {
            setError(error);
            setIsShow(true);
        });

    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }


    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === CBD_PK_INDICES){
            searchListByFilters?.(getByFilters, CBD_PK_INDICES, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ---------------------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER ------------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
            <p>
                    <MaterialReactTable
                        columns={columns}
                        data={data?? []}
                        enableRowSelection //enable some features
                        enableColumnOrdering
                        enableGlobalFilter={false} //turn off a feature
                        enableDensityToggle={true}
                        initialState={{
                            columnVisibility: _initial_columns_state,
                            density: 'compact',
                        }}

                        /* SAVE FILTERS   */

                        onColumnFiltersChange={handleFilterFnChange}
                        state={{ columnFilters }}

                        /* ACTIONS */

                        enableRowActions={true}
                        renderRowActions={({ row }) => (
                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        Events.trigger(EventsType.SEARCH_BY_STUDY_PK_INDICES, row.original);
                                    }}
                                >
                                    <VisibilityIcon />
                                </IconButton>

                            </Box>
                        )}
                        positionToolbarAlertBanner="bottom"
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                <Button
                                    color="primary"
                                    onClick={() => exportToExcel(data, "PKs")}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Export All Data
                                </Button>
                                <Button
                                    disabled={
                                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                    }
                                    onClick={()=>  exportToExcelSelectedRows(table.getSelectedRowModel().rows, "PKs")}

                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Export Selected Rows
                                </Button>
                            </Box>
                        )}
                        />
            </p>
                );
};

const PKIndicesTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_PKIndicesTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(PKIndicesTableCard);
