import React, { useState, useEffect } from 'react';
import { isNotUndefined } from "../../utils/string";
import { IconButton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { iBasket } from "./types";
import ChartPieProduct from "../graphics/ChartPie.Products";
import ChartPieTerepenesConcentrations from "../graphics/ChartPieTerepenesConcentrations";
import ChartPiePKIndices from "../graphics/ChartPie.PKIndices";

interface DynamicModalProps<T> {
    basket: iBasket;
    isOpen: boolean;
    handleCloseModal: () => void;
}

const Statistics = <T extends Record<string, any>>({ basket, isOpen, handleCloseModal }: DynamicModalProps<T>) => {
    const [basketValues, setBasketValues] = useState<iBasket>(basket);

    useEffect(() => {
        setBasketValues(basket);
    }, [basket]);

    if (!isOpen) {
        return null;
    }

    return (
        <>
            {isNotUndefined(basketValues) && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="card-edit">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h3 style={{ width: '90%', justifyContent: 'center' }}> STATISTICS </h3>
                                <IconButton onClick={handleCloseModal} color="primary" style={{ maxWidth: '50px' }}>
                                    <ClearIcon />
                                </IconButton>
                            </div>

                            <div>

                                {/*  PRODUCTS  */}
                                <Accordion sx={{ margin: 0, backgroundColor: 'white', minWidth: 500 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        Products
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ChartPieProduct data={basketValues} />
                                    </AccordionDetails>
                                </Accordion>

                                {/*  TEREPENES  */}
                                <Accordion sx={{ margin: 0, backgroundColor: 'white', minWidth: 500 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        Terepenes Concentrations
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ChartPieTerepenesConcentrations data={basketValues} />
                                    </AccordionDetails>
                                </Accordion>

                                {/*  PK INDICES  */}
                                <Accordion sx={{ margin: 0, backgroundColor: 'white', minWidth: 500 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        PK Indices
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ChartPiePKIndices data={basketValues} />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Statistics;
