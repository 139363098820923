import React, {useEffect, useState} from 'react';

import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../types";
import {CardWithBaseComponent} from "../common/BaseCard";

import {CANNABINOIDS, COMPOUND, MARKET_CBD, REFERENCES, TERPENES, AROMA, PHARMACO_DYNAMICS} from "../../api/Compounds";
import {STUDIES_METABOLITES, SC_PK, SC_THERAPEUTIC_INDICATIONS, CBD_PK_INDICES} from "../../api/Studies";

import {Compound, ProductAnalysisValue, ProductAnalysisMethod, Reference, ProductAnalysisReport} from "../PMI/types";
import {Cannabinoid, Market_CBD, Terpene, Aroma, Strain as StrainObject, PharmacoDynamics} from "../PMI/CBD/types";
import {Metabolite, PK_Indices, TherapeuticIndicator} from "../PMI/Studies/types";

import { saveBulk as saveBulkCompounds } from "../../api/Compounds";
import { saveBulk as saveBulkMetabolites } from "../../api/Studies";
import { saveBulk as saveBulkStrains} from "../../api/Strains";
import {PRODUCTS_ANALYSIS_REPORTS, saveBulk as saveBulkProducts} from "../../api/Products";

import {Button, SelectChangeEvent} from "@mui/material";
import {useMessage} from "../ContextMessage";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {FormControl, IconButton, InputLabel, Select} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import * as XLSX from 'xlsx';
import {RenderDataOnATable} from "../../utils/tables";
import PK from "../PMI/Studies/PK";
import {PRODUCT_ANALYSIS_VALUES, PRODUCTS_ANALYSIS_METHODS} from "../../api/Products";


const _UploadsCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data , searchBy, searchByDoc}) => {

    const UUID = '_UploadsCard';
    const { showWarning, showInfo } = useMessage();

    interface ItemProperties {
        [key: string]: Record<string, any>;
    }

    // @TODO: remove this itemProperties and use data property passed by parameters from App component
    const itemProperties: ItemProperties = {}
    itemProperties[`${COMPOUND}`] = Compound;
    itemProperties[`${CANNABINOIDS}`] = Cannabinoid;
    itemProperties[`${MARKET_CBD}`] = Market_CBD;
    itemProperties[`${REFERENCES}`] = Reference;
    itemProperties['Varieties with Concentrations'] = StrainObject;
    itemProperties[`${STUDIES_METABOLITES}`] = Metabolite;
    itemProperties[`${SC_PK}`] = PK;
    itemProperties[`${SC_THERAPEUTIC_INDICATIONS}`] = TherapeuticIndicator;
    itemProperties[`${TERPENES}`] = Terpene;
    itemProperties[`${AROMA}`] = Aroma;
    itemProperties[`${PHARMACO_DYNAMICS}`] = PharmacoDynamics;
    itemProperties[`${CBD_PK_INDICES}`]= PK_Indices;
    itemProperties[`${PRODUCT_ANALYSIS_VALUES}`]= ProductAnalysisValue;
    itemProperties[`${PRODUCTS_ANALYSIS_METHODS}`]= ProductAnalysisMethod;
    itemProperties[`${PRODUCTS_ANALYSIS_REPORTS}`]= ProductAnalysisReport;

    const listOfItems = Object.keys(itemProperties).sort();

    const [selectedItem, setSelectedItem] = useState('');
    const [validData, setValidData] = useState(false);
    const [excelData, setExcelData] = useState<any[]>([]);

    function checkDataProperties(data: any[], selectedItem: string): boolean {

        if (!listOfItems.includes(selectedItem)) {
            showWarning(`Invalid selectedItem: ${selectedItem}`);
            return false;
        }

        const selectedObject = itemProperties[selectedItem];

        for (let i = 0; i < Math.min(data.length, 10); i++) {
            const item = data[i];
            const requiredProperties = Object.keys(selectedObject);

            for (const property of requiredProperties) {
                if (!(property in item)) {
                    showWarning(` Missing property ${property} found check missing columns in Excel for ${selectedItem} `);
                    return false;
                }
            }
        }
        return true;
    }

    const handleItemChange = (event: SelectChangeEvent<string>) => {
        setSelectedItem(event.target.value as string);
        setExcelData([]);
        setValidData(false);
    }

    const handleUploadClick = async () => {
        console.debug("Selected item:", selectedItem);
        console.debug("Excel Data:", excelData);

        let success = false;

        switch (selectedItem) {
            case COMPOUND:
            case CANNABINOIDS:
            case TERPENES:
            case AROMA:
            case REFERENCES:
            case PHARMACO_DYNAMICS:
            case MARKET_CBD:
                success = await saveBulkCompounds(selectedItem, excelData);
                break;
            case STUDIES_METABOLITES:
            case CBD_PK_INDICES:
                success = await saveBulkMetabolites(selectedItem, excelData);
                break;
            case SC_THERAPEUTIC_INDICATIONS:
            case SC_PK:
                success = await saveBulkMetabolites('CBD_Studies', excelData);
                break;
            case 'Varieties with Concentrations':
                success = await saveBulkStrains(selectedItem, excelData);
                break;
            case PRODUCT_ANALYSIS_VALUES:
            case PRODUCTS_ANALYSIS_REPORTS:
            case PRODUCTS_ANALYSIS_METHODS:
                success = await saveBulkProducts(selectedItem, excelData);
                break;
        }

        if (success) {
            showInfo(' ... all data saved correctly');
            setExcelData([]);
            setValidData(false);
        }
        else showWarning('.. data was not saved properly');

    }

    const handleExcelFileLoaded = (fileData: any) => {
        if (fileData) {

            const workbook = XLSX.read(fileData, { type: 'binary' });
            const sheetName = selectedItem;
            const worksheet = workbook.Sheets[sheetName];

            if (worksheet) {
                const jsonData = XLSX.utils.sheet_to_json(worksheet,  { defval: '' });
                setExcelData(jsonData);
                setValidData(checkDataProperties(excelData, selectedItem));
            } else {
                showWarning(`Sheet '${sheetName}' not found in the Excel file.`);
                setValidData(false);

            }
        }
    }

    useEffect(() => {
        setIsShow(true);
    },[]);


    // RENDER ------

    return (
        <div>
            <div style={{ width: '100%', textAlign: 'center', flexDirection: 'row'}}>
                <FormControl>
                    <InputLabel>Select an item</InputLabel>
                    <Select
                        value={selectedItem}
                        onChange={handleItemChange}
                        style={{ width: '150px', height: '35px'}}
                    >
                        {listOfItems.sort().map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                &nbsp;
                {selectedItem && (
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={(e) => {
                            const file = e.target.files?.[0];
                            if (file) {
                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    const fileData = e.target?.result;
                                    handleExcelFileLoaded(fileData);
                                };
                                reader.readAsBinaryString(file);
                            }
                        }}
                    />
                )}

                {selectedItem && validData && (

                    <Button variant="contained"
                            color="secondary" onClick={handleUploadClick}
                       >
                        <UploadFileIcon /> UPLOAD DATA</Button>
                )}


            </div>

            { excelData.length > 0 &&  <RenderDataOnATable data={excelData} /> }


        </div>


    );
};

const UploadsCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header
                                                                                       , width
                                                                                       ,eventGroupToTrigger
                                                                                       ,showBasket
                                                                                       ,storageValue
                                                                                        ,showContent}) => {
    return <CardWithBaseComponent cardComponent={_UploadsCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  showContent={showContent}
                                  storageValue={storageValue}/>;
};

export default UploadsCard;
