import React, { useEffect, useState } from 'react';
import events from '../../events';
import {EventsType} from '../../events-types';
import {isNotEmpty} from "../../utils/string";

interface LogoComponentProps {
    image: string;
    width: string
}

const LogoIcon: React.FC<LogoComponentProps> = ({ image, width }) => {
    const UUID = '_LogoIcon';
    const [loadingSet, setLoadingSet] = useState<string[]>([]);

    const reset = () => {
        setLoadingSet([]);
    };

    useEffect(() => {
        const handleEventLoading = (eventData: any) => {
            if (isNotEmpty(eventData.detail)){
                setLoadingSet(prevSet => [...prevSet, eventData.detail]);
            }

        };

        const handleEventLoaded = (eventData: any) => {
            if (isNotEmpty(eventData.detail)) {
                setLoadingSet(prevSet => prevSet.filter(item => item !== eventData.detail));

                if (loadingSet.length === 0) {
                    reset();
                }
            }
        };

        events.on(EventsType.LOADING, handleEventLoading, UUID);
        events.on(EventsType.LOADED, handleEventLoaded, UUID);
        events.on(EventsType.CLEAR_ALL_DATA, reset, UUID);

        return () => {
            events.off(EventsType.LOADING, UUID);
            events.off(EventsType.LOADED, UUID);
            events.off(EventsType.CLEAR_ALL_DATA, UUID);
        };
    }, [loadingSet]);

    return (
        <div className={`logo`} style={{ width: width }}>
            <img
                src={image}
                className={`App-logo ${loadingSet.length > 0 ? 'spinning' : ''}`}
                alt="logo"
                onClick={reset}
            />
        </div>
    );
};

export default LogoIcon;
