import React, {useEffect, useMemo, useState} from 'react';
import events from '../../events';
import { EventsType } from '../../events-types';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

interface LogoComponentProps {
    width: string;
    name: string;
}

const MarkIcon: React.FC<LogoComponentProps> = ({ width, name }) => {
    const UUID = '_MarkIcon_' + name;
    const [isVisible, setIsVisible] = useState(false);
    const visibility  = useMemo(() => {
        return (isVisible) ? 'visible' : 'hidden';
    },[isVisible]);

    useEffect(() => {
        const handleEventHasNewData = (event: CustomEvent) => {
            if (event.detail === name && !isVisible) {
                setIsVisible(true);
            }
        };

        const handleEventClear = (event: CustomEvent) => {
                setIsVisible(false);
        };

        events.on(EventsType.HAS_NEW_DATA, handleEventHasNewData, UUID);
        events.on(EventsType.CLEAR_ALL_DATA, handleEventClear, UUID);

        return () => {
            events.off(EventsType.HAS_NEW_DATA, UUID);
            events.off(EventsType.CLEAR_ALL_DATA, UUID);
        };
    }, [UUID, isVisible, name]);

    return (
        <>
           <div  style={{ visibility: visibility }}>
                    <CircleRoundedIcon style={{ color: 'green' ,  width: width , marginLeft: '5px'}}/>
           </div>
        </>
    );
};

export default MarkIcon;
