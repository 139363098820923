import React, {useEffect, useState} from 'react';
import {iBaseCardWithComponentProps, iBaseInnerCardProps,TableAuditData,  iTableAuditData, iAuditData } from "./types";
import {CardWithBaseComponent} from "./common/BaseCard";
import {getAuditsBetweenDates} from "../api/Audits";
import {isNotUndefined, isUndefined} from "../utils/string";
import {
    Box, Button,
    IconButton,
} from "@mui/material";
import {Visibility as VisibilityIcon} from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Events from "../events";
import MaterialReactTable from "material-react-table";
import {getColumns} from "../utils/tables";
import {DatePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {EventsType} from "../events-types";
import {STRAINS_COMP_CONC} from "../api/Strains";

function convertToNewStructure(oldData: iAuditData[]): iTableAuditData[] {

    if (isUndefined(oldData)) return [];
    else {
        return oldData.map((oldItem) => {
            const {id, user, date, difference, reason} = oldItem;

            return {
                id,
                user: user || '',
                date,
                diff_before: (isNotUndefined(difference)) ? difference.before || {} : {},
                diff_after: (isNotUndefined(difference)) ? difference.after || {} : {},
                reason: reason || 'modified',
            };
        });
    }
}

const _AuditTrailCard: React.FC<iBaseInnerCardProps> = ({setIsShow, setData, data}) => {

    const UUID = '_AuditTrailCard';

    const today = dayjs(); // Use dayjs for today's date
    const thirtyDaysAgo = today.subtract(30, 'day');

    const [fromDate, setFromDate] = useState(thirtyDaysAgo);
    const [toDate, setToDate] = useState(today);


    const columns = getColumns(TableAuditData);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15, //customize the default page size
    });



    const getAuditsByRangeDates = async (from: dayjs.Dayjs, to: dayjs.Dayjs, reason?: string) => {

        const last_changes = await getAuditsBetweenDates( from.toISOString(), to.add(1, 'day').toISOString(), reason);
        const m_last_changes = convertToNewStructure(last_changes);
        setData(m_last_changes);
    };


    useEffect(() => {

        getAuditsByRangeDates(fromDate, toDate);

        setIsShow(true);
        setData(TableAuditData);
    }, [fromDate, toDate]);


    return (
        <>
            <Box sx={{display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap'}}>
                <DatePicker
                    label="From Date"
                     value={fromDate}
                    onChange={(date) => setFromDate(date || thirtyDaysAgo)}
                    slotProps={{ textField: { size: 'small' } }}
                />
                <DatePicker
                    label="To Date"
                     value={toDate}
                    onChange={(date) => setToDate(date || today)}
                    slotProps={{ textField: { size: 'small' } }}


                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        getAuditsByRangeDates(fromDate, toDate);
                    }}
                >
                    SHOW MODIFIED
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        getAuditsByRangeDates(fromDate, toDate, 'created');
                    }}
                >
                    SHOW CREATED
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        getAuditsByRangeDates( fromDate, toDate, 'deleted');
                    }}
                >
                    SHOW DELETED
                </Button>

            </Box>

            <MaterialReactTable
                    columns={columns}
                    data={data?? []}
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableGlobalFilter={false} //turn off a feature
                    enableDensityToggle={true}
                    initialState={{
                        density: 'compact',
                    }}

                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    state={{ pagination }} //pass the pagination state to the table

                /* ROW ACTION   */

                    enableRowActions={true}
                    renderRowActions={({ row }) => (
                        <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>

                            {row.original.reason != 'deleted' && (
                            <IconButton
                                size="small"
                                color="secondary"
                                title="View Current Item"
                                onClick={() => {
                                        Events.triggerByID(row.original.id);
                                }}
                            >
                                <VisibilityIcon />
                            </IconButton>
                            )}

                            {row.original.reason === 'deleted' && (
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    title="Create Item"
                                    onClick={() => {
                                      const obj = { ...row.original.diff_before,  _id: row.original.id }
                                      Events.createObject(obj)
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            )}
                        </Box>
                    )}



            />
        </>
    );
};
const AuditTrailCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header,
                                                                                         width,
                                                                                         eventGroupToTrigger,
                                                                                         showBasket,
                                                                                         storageValue }) => {
    return <CardWithBaseComponent cardComponent={_AuditTrailCard}
                                  header={header}
                                  width={width}
                                  eventGroupToTrigger={eventGroupToTrigger}
                                  showBasket={showBasket}
                                  storageValue={storageValue}/>;
};


export default React.memo(AuditTrailCard);
