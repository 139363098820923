export interface iCompound {
    // _key, _id, _rev,
    COMPOUND_NAME: string;
    CAS: string;
    INCHIKEY: string;
    SMILES: string;
    SYNONYMS: string;
    CBD_ID: string;
    PUBCHEM_CID: string;

}

export const Compound: iCompound = {
    COMPOUND_NAME: '',
    CAS: '',
    INCHIKEY: '',
    SMILES: '',
    SYNONYMS: '',
    CBD_ID: '',
    PUBCHEM_CID: ''
}


export interface iReference {
    REFERENCE_ID: string;
    REFERENCE_YEAR: string;
    REFERENCE_DOI: string;
    REFERENCE_PMID: string;
    REFERENCE_NCTID: string;
    REFERENCE_TITLE: string;
    REFERENCE_VOLUME: string;
    REFERENCE_PAGES: string;
    REFERENCE_JOURNAL: string;
    REFERENCE_TITLE_JOURNAL: string;
    REFERENCE_DOCUMENT_TYPE: string;
    REFERENCE_AUTHORS: string;
    REFERENCE_SENTENCE: string;
    REFERENCE_APPROVAL_DOCUMENT_SOURCE: string;
    REFERENCE_GRANTID: string;
}

export const Reference: iReference = {
    REFERENCE_ID : '',
    REFERENCE_YEAR : '',
    REFERENCE_DOI : '',
    REFERENCE_PMID : '',
    REFERENCE_NCTID : '',
    REFERENCE_TITLE : '',
    REFERENCE_VOLUME : '',
    REFERENCE_PAGES : '',
    REFERENCE_JOURNAL : '',
    REFERENCE_TITLE_JOURNAL : '',
    REFERENCE_DOCUMENT_TYPE : '',
    REFERENCE_AUTHORS : '',
    REFERENCE_SENTENCE : '',
    REFERENCE_APPROVAL_DOCUMENT_SOURCE : '',
    REFERENCE_GRANTID : ''
}

export interface iProductsAnalysisMethod {
    CLASS: string;
    METHOD_DESCRIPTION : string;
    TEST_METHOD_CODE: string;
    MATRIX_CODE: string;

}

export const ProductAnalysisMethod: iProductsAnalysisMethod  = {
    CLASS: '',
    METHOD_DESCRIPTION : '',
    TEST_METHOD_CODE: '',
    MATRIX_CODE: '',
}

export interface iProductAnalysisReport {
    ANALYSIS_TYPE: string;
    REPORT_SOURCE: string;
    REPORT_PROJECT_ID: string;
    DATE: string;
    MATRIX_CODE: string;
    SAMPLE_PREPARATION: string;
    PRODUCT: string;
    SAMPLE_ID: string;
    PARAMETERS_OF_SMOKE_OR_AEROSOL_GENERATION: string;
    HEATING_DEVICE: string;
}

export const ProductAnalysisReport: iProductAnalysisReport = {
    ANALYSIS_TYPE: '',
    REPORT_SOURCE: '',
    REPORT_PROJECT_ID:'',
    DATE: '',
    MATRIX_CODE: '',
    SAMPLE_PREPARATION: '',
    PRODUCT: '',
    SAMPLE_ID: '',
    PARAMETERS_OF_SMOKE_OR_AEROSOL_GENERATION: '',
    HEATING_DEVICE: '',
}

export interface iProductAnalysisValue {
    ANALYSIS_TYPE: string;
    TEST_METHOD_CODE: string;
    CLASS: string;
    REPORT_PROJECT_ID: string;
    PRODUCT: string;
    INCHIKEY: string;
    UNIT: string;
    AVERAGE: string;
    STD_DEV: string;
    LOD: string;
    LOQ: string;
    NUMBER_OF_REPLICATES: string;
    NUMBER_OF_ITEMS_PER_REPLICATE: string;
}

export const ProductAnalysisValue: iProductAnalysisValue = {
    ANALYSIS_TYPE: '',
    TEST_METHOD_CODE: '',
    CLASS: '',
    REPORT_PROJECT_ID: '',
    PRODUCT: '',
    INCHIKEY: '',
    UNIT: '',
    AVERAGE: '',
    STD_DEV: '',
    LOQ: '',
    LOD: '',
    NUMBER_OF_ITEMS_PER_REPLICATE: '',
    NUMBER_OF_REPLICATES: ''
}
