import React from 'react';

import Search from './Search'
import logo from '../img/logo.svg';
import '../css/NavBar.css';
import User from "./User";
import LogoIcon from "./graphics/LogoIcon";

const Navbar = () => {
    return (
        <div className="navbar col-12">

            <LogoIcon image={logo} width={'90px'} />

            <div>
                <h2>CannaBoRN Platform - {process.env.REACT_APP_VERSION} <span className="notation">({process.env.REACT_APP_ENVIRONMENT}) {process.env.REACT_APP_BUILD}</span></h2>
            </div>

            <Search/>

            <User />

            &nbsp;&nbsp;&nbsp;

        </div>
    )
};

export default Navbar;
