import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Button} from "@mui/material";

interface ImageModalProps {
    show: boolean;
    onHide: () => void;
    imageSrc: string;
    altText: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ show, onHide, imageSrc, altText }) => {
    return (
        <Modal show={show} onHide={onHide} centered style={{ display: 'flex', alignItems: 'center' }}>
           <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={imageSrc} alt={altText} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
            </Modal.Body>
            <Modal.Footer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                         variant="contained"
                         size="small"
                         color="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageModal;
