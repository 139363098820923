import '../utils/arrays';
import DataApiCache from "./Cache";
import {iChemSpiderChemicalData} from "../components/externals/types";


const URL_CHEMSPIDER_ID = process.env.REACT_APP_PROXY_API_BASE_URL + "/chemspider/Chemical-Structure._#CSID#_.html";
const URL_CHEMSPIDER_SEARCH = process.env.REACT_APP_PROXY_API_BASE_URL + "/chemspider/Search.aspx?q=_#TEXT#_";


const _api = new DataApiCache();


interface ExperimentalProperty {
    property: string;
    values: Array<{ value: string; source: string; link: string }>;
}

interface PredictedProperty {
    property: string;
    values: Array<{ property: string, value: string }>;
}


function parseCompound(html: any) {
    const exp_data = extractExperimentalProperties(html);
    const pred_data = extractPredictedProperties(html);

    return {
        experimental: exp_data,
        predicted: pred_data
    };
}

function extractTableData(html: string): iChemSpiderChemicalData[] {
    const chemData: iChemSpiderChemicalData[] = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const rows = doc.querySelectorAll('table.view-grid tbody tr');

    rows.forEach((row) => {
        const columns = row.querySelectorAll('td');
        const rowData: iChemSpiderChemicalData = {
            id: (columns[0].textContent?.trim().match(/\d+/) || [])[0] || '',
            // structure: columns[1].textContent?.trim() || '',
            molecularFormula: columns[2].textContent?.trim() || '',
            molecularWeight: columns[3].textContent?.trim() || '',
            // numDataSources: columns[4].textContent?.trim() || '',
            // numReferences: columns[5].textContent?.trim() || '',
            // numPubMed: columns[6].textContent?.trim() || '',
            // numRSC: columns[7].textContent?.trim() || '',
        };
        chemData.push(rowData);
    });

    return chemData;
}
function extractExperimentalProperties(html: string): ExperimentalProperty[] {
    const properties: ExperimentalProperty[] = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const propertyItems: NodeListOf<HTMLSpanElement> = doc.querySelectorAll('li > span.user_data_category_name');

    propertyItems.forEach((category: HTMLSpanElement) => {
        if (category.textContent?.trim() === 'Experimental Physico-chemical Properties') {
            const propertyBlock: HTMLLIElement | null = category.closest('li');
            if (propertyBlock) {
                const propertyItems: NodeListOf<HTMLLIElement> = propertyBlock.querySelectorAll('ul.expand-list li');

                propertyItems.forEach((propertyItem: HTMLLIElement) => {
                    const propertyName: string = propertyItem.querySelector('span.user_data_property_name')?.textContent?.trim() || '';
                    const values: Array<{ value: string; source: string; link: string }> = [];

                    const rows: NodeListOf<HTMLTableRowElement> = propertyItem.querySelectorAll('table tr');
                    rows.forEach((row: HTMLTableRowElement) => {
                        const value: string = row.querySelector('td')?.textContent?.trim() || '';
                        const strongTag: HTMLElement | null = row.querySelector('strong');
                        const source: string = strongTag?.textContent?.trim() || '';
                        const linkTag: HTMLAnchorElement | null = row.querySelector('a');
                        const link: string = linkTag?.getAttribute('href') || '';

                        values.push({ value, source, link });
                    });

                    properties.push({ property: propertyName, values });
                });
            }
        }
    });

    return properties;
}


function extractPredictedProperties(html: string): PredictedProperty[] {
    const properties: PredictedProperty[] = [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const acdLabsTabContent: HTMLElement | null = doc.querySelector('#acdLabsTab');

    if (acdLabsTabContent) {
        const propertySections: NodeListOf<HTMLDivElement> = acdLabsTabContent.querySelectorAll('div.column.two');

        propertySections.forEach((section: HTMLDivElement) => {
            const rows: NodeListOf<HTMLTableRowElement> = section.querySelectorAll('table#acdlabs-table tr');

            const propertyName: string | null = acdLabsTabContent.querySelector('div.tab-content > p.info-tab-header > strong')?.textContent?.trim() || '';
            const propertyValues: Array<{ property: string, value: string }> = [];

            rows.forEach((row: HTMLTableRowElement) => {
                const titleCell: HTMLTableCellElement | null = row.querySelector('td.prop_title');
                const valueCell: HTMLTableCellElement | null = row.querySelector('td.prop_value_nowrap');

                if (titleCell && valueCell) {
                    const property: string = titleCell.textContent?.trim() || '';
                    const value: string = valueCell.textContent?.trim() || '';

                    propertyValues.push({ property, value });
                }
            });

            if (propertyName && propertyValues.length > 0) {
                properties.push({ property: propertyName, values: propertyValues });
            }
        });
    }

    return properties;
}





export const getCompoundByCSID = async (csid: string) => {

    const address = URL_CHEMSPIDER_ID.replace('_#CSID#_', csid);

    let data:any = await _api.get(address);

    data = parseCompound(data);

    return data;
}

export const getCompounds = async (text: string) => {

    const address = URL_CHEMSPIDER_SEARCH.replace('_#TEXT#_', text);

    let data:any = await _api.get(address);
    data = extractTableData(data);
    
    return data;
}



