//
// Base types for view Cards
//

import React from "react";
import {MRT_ColumnFiltersState} from "material-react-table";


export type CardComponentType = React.ComponentType<iBaseInnerCardProps>;
export interface iBaseInnerCardProps {
    setIsShow: (isShow: boolean) => void;
    setError: (error: string) => void;
    setData:(data:any) => void;
    data: any;
    setTitle:(title:string) => void;
    setSubtitle:(subtitle:string) => void;

    // textSearch: string;
    // setTextSearch: (text_search: string) => void;

    storageValue?: string;

    searchListBy?: (   func: (collection: string, key: string, value: string) => Promise<any[]>,
                        collection: string,
                        key: string,
                        value: string
                    )  => void;

    searchListByFilters?: (   func: (collection: string, filters: any[]) => Promise<any[]>,
                            collection: string,
                            filters: any[],
                            title: string
                        ) => void;

    searchBy?: (
            func: (collection: string, key: string, value: string) => Promise<any[]>,
            collection: string,
            key: string,
            value: string
        ) => Promise<any>;

    searchByDoc?:(func: (collection: string, doc: any) => Promise<any[]>
        , collection:string, doc:any, onlyIDs: boolean, titleKey:string, triggerEvent?: boolean)  => void;

    setColumnFilters: (filters: MRT_ColumnFiltersState) => void;
    columnFilters:any;
    isActive:boolean;
}

export interface iBaseCardWithComponentProps {
    cardComponent: CardComponentType;
    header: string;
    width?: string;
    showContent?: boolean;
    showBasket?:boolean;
    storageValue?:string;
    eventGroupToTrigger?:string;
}


// ---------- AUDIT TRAIL -------------------

export interface iDifference {
    before: { [key: string]: any };
    after: { [key: string]: any };
}

export interface iAuditData {
    id: string;
    user: string;
    date: string;
    difference: iDifference;
    reason: string | null;
}

export interface iTableAuditData {
    id: string;
    user: string;
    date: string;
    diff_before:  Record<string, any>;
    diff_after:  Record<string, any>;
    reason: string | null;
}

export const TableAuditData : iTableAuditData = {
    id: '',
    user: '',
    date: '',
    diff_before: {},
    diff_after: {},
    reason:  null
}



