import MultiBasket from "./services/MultiBasket";
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {getCurrentUserNameAndRoles} from "./services/Cognito";

export const multiBaskets = new MultiBasket();
export const BasketsContext = createContext<MultiBasket>(multiBaskets);
export const UserContext = createContext<string| null>(null);
export const RolesContext = createContext<string[]>([]);


export function useStorage() {  return useContext(BasketsContext); }
export function useUser() { return useContext(UserContext); }
export function useRoles() { return useContext(RolesContext); }

export function StorageProvider({ children }: { children: ReactNode }) {
    const [user, setUser] = useState<string | null>(null);
    const [roles, setRoles] = useState<string[]>([]);

    const fetchCurrentUser = async () => {
        try {
            const { user, roles} = await getCurrentUserNameAndRoles();
            setUser(user);
            setRoles(roles);
        } catch (error) {
            console.log('Error setting current user:', error);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    return (
        <BasketsContext.Provider value={multiBaskets}>
            <UserContext.Provider value={user}>
            <RolesContext.Provider value={roles}>
                {children}
            </RolesContext.Provider>
            </UserContext.Provider>
        </BasketsContext.Provider>
    );
}
