import React, { useState } from 'react';
import {Button} from "@mui/material";
import { EventsType } from '../../../events-types';
import Events from "../../../events";
import Card from "react-bootstrap/Card";
import ImageModal from "../../graphics/ImageModal";

interface PubchemIdProps {
    data: {
        PUBCHEM_CID: string;
        cid: string;
    };
}

function PubchemId({ data }: PubchemIdProps) {
    const [hoveredCID, setHoveredCID] = useState(String(data.PUBCHEM_CID || data.cid).trim());
    const [modalOpen, setModalOpen] = useState(false);

    const show2DImage = (cid: string) => {
        setHoveredCID(cid);
        toggleModal();
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const _data = String(data.PUBCHEM_CID || data.cid).trim();
    if (typeof _data === 'undefined' || _data === 'undefined') {
        return <></>;
    } else {
        const cids = _data.split(',').map((value) => value.trim());
        return (
            <>
                <ImageModal
                    show={modalOpen}
                    onHide={toggleModal}
                    imageSrc={`https://pubchem.ncbi.nlm.nih.gov/image/imgsrv.fcgi?cid=${hoveredCID}&t=l`}

                    altText="Associated Image"
                />
                {cids.filter((cid) => cid !== '').map((cid) => (
                    <React.Fragment key={cid}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={`https://pubchem.ncbi.nlm.nih.gov/image/imgsrv.fcgi?cid=${cid}&t=s`}
                                onClick={() => show2DImage(cid)}
                                style={{ cursor: 'pointer' }}
                                title={"click here to open the image bigger"}
                            />
                            <div style={{ marginLeft: '10px' }}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        Events.trigger(EventsType.SEARCH_BY_PUBCHEM_CID, cid);
                                    }}
                                >
                                    CID {cid}
                                </Button>
                                <br />
                                <br />
                                <Card.Link target="_blank" href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cid}`}>
                                    Open PubChem
                                </Card.Link>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </>
        );
    }
}

export default PubchemId;
