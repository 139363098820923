import React, {useEffect, useState, useMemo, useContext, useCallback, useRef, ChangeEvent} from 'react';
import {Box, IconButton, Button, styled, FormControlLabel, Checkbox, makeStyles} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import MaterialReactTable, {
    MRT_Cell,
    MRT_ColumnDef
} from 'material-react-table';
import Events from '../../events';
import { EventsType } from '../../events-types';
import { iBaseCardWithComponentProps, iBaseInnerCardProps, CardComponentType } from '../types';
import { CardWithBaseComponent } from '../common/BaseCard';
import EditableText from '../common/EditableText';
import { BasketsContext } from "../../App.Context";
import { exportBasketToExcel, importExcelToBasket} from "../../utils/export_xlsx";
import {getHeadersFromHeaderName} from "../../utils/tables";
import {Basket} from "./Basket";
import {iBasket, iBasketItem} from "./types";
import {save, deleteDoc} from "../../api/Baskets";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Statistics from "./Statistics";
import BarChartIcon from "@mui/icons-material/BarChart";
import {useModal} from "../common/Modal";

const _BasketTableCard: React.FC<iBaseInnerCardProps> = ({
                                                            setIsShow,
                                                            setError,
                                                            setData,
                                                            data,
                                                            setTitle,
                                                            setSubtitle,

                                                            storageValue,
                                                        }) => {
    const multiStorage = useContext(BasketsContext);
    const message = '... click here to edit info content about this basket ';

    const UUID = '_BasketTable_' + storageValue;
    const [basket, setBasket] = useState<iBasket>(multiStorage.getBasket(storageValue || 'undefined'));
    const [items, setItems] = useState<iBasketItem[]>([]);
    const [info, setInfo] = useState(message);
    const [isShared, setIsShared] = useState<boolean>(basket.shared);

    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);;

    const { isOpen, handleOpenModal, handleCloseModal } = useModal();

    const hasItemsToPlot = (): boolean => {
        const headersToCheck = ["List of Products Analysis Values", "Concentration of terpenes", "PK Indices"];
        return basket.items.some(item => headersToCheck.some(header => item.HEADER.startsWith(header)));
    };

    const handleImportExcel = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = e.target?.result;
                const data = importExcelToBasket(fileData);
                data.forEach((item) => {
                    basket.items.push(  {
                        id: '',
                        TITLE: item.TAB_NAME,
                        HEADER: item.HEADER,
                        INFO: null,
                        DATA: item.DATA,
                        filter: []
                    });
                });

                loadBasket();
            };
            reader.readAsBinaryString(file);
        }
    }

    const handleInfoSave = (newText: string) => {
        setInfo(newText);
        basket.info = newText;
        save(basket);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsShared(event.target.checked)
        basket.shared = event.target.checked;
        save(basket);
    };

    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: 'HEADER',
                header: 'HEADER',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: false
            },
            {
                accessorKey: 'TITLE',
                header: 'TITLE',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: true
            },
            {
                accessorKey: 'INFO',
                header: 'INFO',
                muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
                enableEditing: true
            },
        ],
        []
    );

    function getOneProperty(data: any) {
        return data.COMPOUND_NAME || data.NAME || data.cmpdname
                || data.TITLE || data.STRAIN_ID || data.TEST_METHOD_CODE ;
    }

    function parseData(data: any): any[] {
        return data.map((item: any) => {
            const { DATA, HEADER, TITLE, INFO, id  } = item;
            const _info = Array.isArray(DATA) ? `(${DATA.length})` : getOneProperty(DATA);
            return {
                HEADER,
                TITLE,
                INFO: INFO || _info,
                id: id
            };
        });
    }

    const loadBasket = useCallback(async () => {
        setIsShow(true);
        setTitle(basket.name || '');
        setInfo( basket.info || message);
        setItems(parseData(basket.items));
        setIsShared(basket.shared);

        if (getDefaultBasket() === basket.name){
            reloadAll();
        }

    }, [storageValue, basket, message, parseData]);

    useEffect(() => {
       loadBasket();
    }, [loadBasket]);

    useEffect(() => {
        const handleBasketAdd = (event: { detail: any }) => {
            if (event.detail.STORAGE_KEY === basket.name) {
                let newItem = event.detail;
                delete newItem.STORAGE_KEY;
                basket.items.push(newItem);
                save(basket);
                loadBasket();
            }
        };

        Events.on(EventsType.BUCKET_ADD, handleBasketAdd, UUID);
        Events.on(EventsType.BUCKET_DELETE_OBJECT, loadBasket, UUID);
        Events.on(EventsType.BUCKET_DELETE_ALL, loadBasket, UUID);

        return () => {
            // Clean up event listeners on component unmount
            Events.off(EventsType.BUCKET_ADD, UUID);
            Events.off(EventsType.BUCKET_DELETE_OBJECT, UUID);
            Events.off(EventsType.BUCKET_DELETE_ALL, UUID);
        };
    }, [UUID, loadBasket, storageValue, basket]);

    async function deleteObject(row: any) {
        setBasket(Basket.deleteItem(basket, row.id));
        await save(basket);
        Events.trigger(EventsType.BUCKET_DELETE_OBJECT, {});

    }

    async function deleteAllData() {
        const confirmation = window.confirm('Are you sure you want to delete all data inside this basket?');
        if (confirmation && basket) {
            basket.items = [];
            await save(basket);
            Events.trigger(EventsType.BUCKET_DELETE_ALL, []);

        }
    }

    function deleteBasket() {
        const confirmation = window.confirm('Are you sure you want to delete this Basket?');
        if (confirmation) {
            Events.trigger(EventsType.BUCKET_TO_DELETE, storageValue);
        }
    }

    async function loadObject(row: any) {

        basket?.items.some((item: any) => {
            if (item.id === row.id) {
                Events.trigger(EventsType.BUCKET_RELOAD_OBJECT, item);
                return true;
            }
            return false;
        });
    }

    async function reloadAll() {
        basket?.items.forEach((item: any) => {
            Events.trigger(EventsType.BUCKET_RELOAD_OBJECT, item);
        });
    }

    function renameBasket() {
        const newName = window.prompt('Rename this Basket', basket.name);
        if (newName) {

            if (getDefaultBasket() == basket.name) {
                setDefaultBasket(newName)
            }

            Events.trigger(EventsType.BUCKET_RENAME, {
                oldBasketName: basket.name,
                newBasketName: newName,
            });
        }
    }

    const handleSaveCell = async (cell: MRT_Cell<any>, name: string, value: any) => {
        const uuid = cell.row.original.id;
        const originalItem = Basket.getItemById(basket, uuid);

        if (originalItem) {

            const updatedItem = {
                ...originalItem,
                [name]: value,
            };

            setBasket(Basket.updateItem(basket, updatedItem));
            await save(basket);
        }
    };

    function setDefaultBasket(name?: string | undefined){
        localStorage.setItem("DEFAULT_BASKET", name || basket.name);
    }

    function getDefaultBasket() {
        return localStorage.getItem("DEFAULT_BASKET");
    }

    function itemsMergedWithData(storage: iBasket | undefined) {
        const mergedItems: any[] = [];

        storage?.items.forEach((item : iBasketItem) => {
            const { HEADER, TITLE } = item;
            const data = item.DATA || [];

            const newData = {
                    TAB_NAME: `${HEADER} ${TITLE}`,
                    DATA: data,
                    HEADER: getHeadersFromHeaderName(HEADER)
                };

            mergedItems.push(newData);
        });

        return mergedItems;
    }


    return (
        <span>

             {isOpen && (
                 <Statistics
                     basket={basket}
                     isOpen={isOpen}
                     handleCloseModal={handleCloseModal}
                 />
             )}

            <MaterialReactTable
                columns={columns}
                data={items ?? []}
                enableColumnOrdering
                enableGlobalFilter={false} //turn off a feature
                enableDensityToggle={true}
                initialState={{
                    density: 'compact',
                }}

                /* EDIT CELLS   */

                editingMode="cell"
                enableEditing
                muiTableBodyCellEditTextFieldProps={({ cell }) => ({
                    onBlur: (event) => {
                        handleSaveCell(cell, event.target.name, event.target.value);
                        cell.row._valuesCache[event.target.name] = event.target.value; // trick
                    },
                })}

                /* ROW ACTION   */

                enableRowActions={true}
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => {
                                deleteObject(row.original);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                        &nbsp;
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => {
                                loadObject(row.original);
                            }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Box>
                )}

                /*  GLOBAL ACTIONS */

                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap', width: '85%'}}>
                        <Button
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            variant="contained"
                        >
                            Actions
                        </Button>

                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            slotProps={{
                                paper: {
                                    sx: {
                                        backgroundColor: 'deepskyblue'
                                    },
                                }
                            }}

                        >

                            {basket.items && basket.items.length > 0 && (
                                <MenuItem
                                    sx={{ '&:hover': { backgroundColor: 'white' } }}
                                    onClick={() => exportBasketToExcel(itemsMergedWithData(basket), basket.name)}>
                                    <DownloadIcon /> &nbsp; Export
                                </MenuItem>
                            )}

                            <MenuItem
                                sx={{ '&:hover': { backgroundColor: 'white' } }}
                                onClick={(e) => inputFileRef.current?.click()}>
                                <UploadIcon /> &nbsp; Import
                                <input
                                    type="file"
                                    accept=".xlsx, .xls"
                                    ref={inputFileRef}
                                    style={{ display: 'none' }}
                                    onChange={handleImportExcel}
                                />
                            </MenuItem>

                            <MenuItem  sx={{ '&:hover': { backgroundColor: 'white' } }} onClick={deleteAllData}>
                                <DeleteIcon /> &nbsp; Delete All Data
                            </MenuItem>

                            <MenuItem  sx={{ '&:hover': { backgroundColor: 'white' } }} onClick={reloadAll}>
                                <ReplayIcon /> &nbsp; Load All Data
                            </MenuItem>

                            <MenuItem  sx={{ '&:hover': { backgroundColor: 'white' } }} onClick={deleteBasket}>
                                <DeleteIcon /> &nbsp; Delete Basket
                            </MenuItem>

                            <MenuItem
                                sx={{ '&:hover': { backgroundColor: 'white' } }}
                                onClick={(event) => event.stopPropagation()}  // Prevent menu close on click
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isShared}
                                            onChange={handleCheckboxChange}
                                            name="shared"
                                            color="primary"
                                        />
                                    }
                                    label="Shared"
                                />
                            </MenuItem>

                            <MenuItem  sx={{ '&:hover': { backgroundColor: 'white' } }} onClick={() => setDefaultBasket() }>Set As Default</MenuItem>

                            <MenuItem  sx={{ '&:hover': { backgroundColor: 'white' } }} onClick={renameBasket}>Rename</MenuItem>
                        </Menu>

                        {hasItemsToPlot() && (
                            <Button  variant="contained"
                                     title="open graphs"
                                     onClick={handleOpenModal}>
                                <BarChartIcon /> GRAPHS
                            </Button>
                        )}

                        <EditableText text={info} width={'85%'} onSave={handleInfoSave} />

                    </Box>
                )}

            />
         </span>

    );
};

const BasketTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({
                                                                                          header,
                                                                                          width,
                                                                                          showContent,
                                                                                          showBasket,
                                                                                          storageValue,
                                                                                      }) => {
    return (
        <CardWithBaseComponent
            cardComponent={_BasketTableCard as CardComponentType}
            header={header}
            width={width}
            showContent={showContent}
            showBasket={showBasket}
            storageValue={storageValue}
        />
    );
};

export default React.memo(BasketTableCard);
