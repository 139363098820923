export interface iPubchem {
    // _key, _id, _rev,
    NAME: string;
    VALUES: string;
    DATA_TYPE: string;
}

export const Pubchem: iPubchem = {
    NAME: '',
    VALUES: '',
    DATA_TYPE: ''
}


export interface iPBClinicalTrial {
    updatedate: string;
    gid: string;
    srcid: string;
    ctid: string;
    cids: string;
    link: string;
    title: string;
    conditions: string;
    interventions: string;
    phase: string;
    status: string;
    diseaseids: string;
}

export const PBClinicalTrials: iPBClinicalTrial = {
    updatedate: '',
    gid: '',
    srcid: '',
    ctid: '',
    cids: '',
    link: '',
    title: '',
    conditions: '',
    interventions: '',
    phase: '',
    status: '',
    diseaseids: ''
}

export interface iClinicalTrial {
    NCT_ID: string;
    TITLE: string;
    STATUS: string;
    MAIN_SPONSOR: string;
    PHASE: string;
    SUMMARY: string;
    DESCRIPTION: string;
}

export const ClinicalTrial : iClinicalTrial = {
    NCT_ID: '',
    TITLE: '',
    STATUS: '',
    MAIN_SPONSOR: '',
    PHASE: '',
    SUMMARY: '',
    DESCRIPTION: ''
}


export interface iCannabisDB {
    accession: string;
    average_molecular_weight: string;
    bigg_id: string;
    biocyc_id: string;
    cas_registry_number: string;
    chebi_id: string;
    chemical_formula: string;
    chemspider_id: string;
    creation_date: string;
    description: string;
    drugbank_id: string;
    // predicted_properties: string;
    // experimental_properties: string;
    foodb_id: string;
    inchi: string;
    inchikey: string;
    iupac_name: string;
    kegg_id: string;
    knapsack_id: string;
    metlin_id: string;
    monisotopic_molecular_weight: string;
    name: string;
    pdb_id: string;
    phenol_explorer_compound_id: string;
    pubchem_compound_id: string;
    secondary_accessions: string;
    smiles: string;
    synonyms: string;
    taxonomy: string;
    traditional_iupac: string;
    update_date: string;
    version: string;
    wikipedia_id: string;
}

export const CannabisDB : iCannabisDB = {
    accession: '',
    average_molecular_weight: '',
    bigg_id: '',
    biocyc_id: '',
    cas_registry_number: '',
    chebi_id: '',
    chemical_formula: '',
    chemspider_id: '',
    creation_date: '',
    description: '',
    drugbank_id: '',
    // predicted_properties: '',
    // experimental_properties: '',
    foodb_id: '',
    inchi: '',
    inchikey: '',
    iupac_name: '',
    kegg_id: '',
    knapsack_id: '',
    metlin_id: '',
    monisotopic_molecular_weight: '',
    name: '',
    pdb_id: '',
    phenol_explorer_compound_id: '',
    pubchem_compound_id: '',
    secondary_accessions: '',
    smiles: '',
    synonyms: '',
    taxonomy: '',
    traditional_iupac: '',
    update_date: '',
    version: '',
    wikipedia_id: ''
}


export interface iChemSpiderChemicalData {
    id: string;
   // structure: string;
    molecularFormula: string;
    molecularWeight: string;
    // numDataSources: string;
    // numReferences: string;
    // numPubMed: string;
    // numRSC: string;
}

export const ChemSpiderChemicalData : iChemSpiderChemicalData = {
    id: '',
  //  structure: '',
    molecularFormula: '',
    molecularWeight: '',
    // numDataSources: '',
    // numReferences: '',
    // numPubMed: '',
    // numRSC: '',
}
