import React, {useEffect} from "react";
import {iBaseCardWithComponentProps, iBaseInnerCardProps} from "../types";
import {CardWithBaseComponent} from "../common/BaseCard";
import Events from "../../events";
import {EventsType} from "../../events-types";

import {getCannabis} from "../../api/CannabisDB"
import {isNotEmpty} from "../../utils/string";
import {isFieldNamed, renderFields, returnCBDID, returnKEY, returnPubchemId} from "../../utils/pair_entry";
import {CannabisDB} from "./types";

const _CannabisDBCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError, setData, data, setTitle, isActive }) => {

    const UUID = '_CannabisDBCard';

    // SEARCH --------------------

    async function searchByCBDID (cbd_id: string) {

        if (cbd_id === null || cbd_id === data.accession) return;

        setIsShow(false);
        try {
            const response = await getCannabis(cbd_id);

            if (isNotEmpty(response)) {
                setTitle(cbd_id);
                setData(response);
                setIsShow(true);
            }
        } catch (error:any) {
            setError(error);
            setIsShow(true);
        }
    }

    const searchByCBDIDEvent = (event: { detail: any }) => {
        if (isActive){
            searchByCBDID(event.detail);
        }
    }

    // EVENTS ------------------

    useEffect(() => {

        Events.on(EventsType.SEARCH_BY_CBD_ID, searchByCBDIDEvent, UUID);

        return () => {
            Events.off(EventsType.SEARCH_BY_CBD_ID, UUID);
        }
    }, [isActive]);

    // RENDER ---------------

    function renderField(fieldName: string, digits: number = 2) {
        const fieldValue = data[fieldName];

        if (fieldValue) {

            if (isFieldNamed(fieldName ,'pubchem_compound_id', data)) {
                return returnPubchemId(data);
            }
            else if (isFieldNamed(fieldName ,'inchikey', data)) {
                return returnKEY(data.INCHIKEY || data.inchikey, 'INCHIKEY', EventsType.SEARCH_BY_INCHIKEY);
            }
            else if (isFieldNamed(fieldName ,'accession', data)) {
                return returnCBDID(data);
            }
            else {

                return (
                    <>
                        <b>{fieldName.replace("_", " ")}</b> :{fieldValue}
                        <br />
                    </>
                );
            }

        }

        return null;
    }

    return(
        <p>
            {renderFields(CannabisDB, data)}
        </p>
    );

}

const CannabisDBCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_CannabisDBCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};


export default React.memo(CannabisDBCard);

