import React, {useEffect, useState} from 'react';
import { useModal } from '../Modal';
import {Button, IconButton, Tooltip} from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import {Clear} from "@mui/icons-material";
import WizardSearchStep2 from "./WizardSearchStep2";
import WizardSearchStep1 from "./WizardSearchStep1";
import Events from "../../../events";
import {EventsType} from "../../../events-types";

interface WizardSearchProps {
    data: Record<string, any>;
}

const WizardSearch: React.FC<WizardSearchProps> = ({ data }) => {
    const { isOpen, handleOpenModal, handleCloseModal } = useModal();

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const [selectedObject, setSelectedObject] = useState<any>(null);
    const [propertyValues, setPropertyValues] = useState<Array<{ property: string; value: string | number; operator: string }>>([]);


    // --------

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleCloseModal();
            }
        };

        handleAddProperty();

        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    // --------

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleObjectSelect = (_key: string) => {
        setSelectedKey(_key);
        const selectedData = data[_key || ''];
        setSelectedObject(selectedData);
    };

    const handlePropertyValueChange = (index: number, updatedProperty : { property: string, value: string | number, operator: string } ) => {
        setPropertyValues((prevPropertyValues) => {
            const updatedPropertyValues = [...prevPropertyValues];
            const updatedValue = updatedProperty.value;

            if (typeof updatedValue === 'string' && !isNaN(Number(updatedValue))) {
                updatedProperty.value = Number(updatedValue);
            }

            updatedPropertyValues[index] = updatedProperty;
            return updatedPropertyValues;
        });
    };

    const handleAddProperty = () => {
        setPropertyValues((prevPropertyValues) => [
            ...prevPropertyValues,
            { property: '', value: '', operator: '==' },
        ]);
    };

    const handleExecute = () => {
        Events.trigger(EventsType.SEARCH_WIZARD, { component: selectedKey, filters: propertyValues});
        handleCloseModal();
    };

    function reset() {
        setPropertyValues([]);
        setSelectedObject(null);
        setCurrentStep(1);
    }

    // ------ RENDER

    return (
        <div>
            <Tooltip title="Search data using Wizard" placement="top" arrow>
                <Button
                    style={{ height: '32px', boxShadow: '0px 0px 0px 0px', marginRight: '5px'}}
                    variant="contained"
                    color="primary"
                    onClick={() => {  reset(); handleOpenModal({}); } }
                    aria-label={"Open Wizard"}
                >
                    <AutoFixHighIcon />
                </Button>
            </Tooltip>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">

                        <div className="card-edit">

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: '500px' }}>
                                <h2 style={{ width: '90%' }}>Advanced Search Wizard</h2>
                                <IconButton
                                    style={{ width: '30px' }}
                                    onClick={handleCloseModal}
                                            color="primary">
                                    <Clear />
                                </IconButton>
                            </div>

                            {currentStep === 1 && (
                                <WizardSearchStep1
                                    onSelect={handleObjectSelect}
                                    onNext={handleNextStep}
                                    data={data}
                                />
                            )}
                            {currentStep === 2 && (
                                <>
                                    <WizardSearchStep2
                                        selectedKey={selectedKey}
                                        selectedObject={selectedObject}
                                        onNext={handleExecute}
                                        onAddProperty={handleAddProperty}
                                        propertyValues={propertyValues}
                                        onPropertyValueChange={handlePropertyValueChange}
                                    />
                                </>

                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WizardSearch;
