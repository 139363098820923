import React, { useState, useEffect } from 'react';
import { useMessage } from "../ContextMessage";
import { useModal } from "./Modal";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import Events from "../../events";
import {EventsType} from "../../events-types";

interface DynamicModalProps<T> {
    schema: T;
    DATATYPE: string;
    data: Partial<T>; // Partial type to allow partial data as initial values
    deleteDoc: (doc: any) => any;
}

const DisplayDeleteModal = <T extends Record<string, any>>({ schema, DATATYPE, data, deleteDoc }: DynamicModalProps<T>) => {
    const [formValues, setFormValues] = useState<Partial<T>>(data);
    const { showWarning, showInfo } = useMessage();
    const { isOpen, handleOpenModal, handleCloseModal } = useModal();

    useEffect(() => {
        setFormValues(data);
    }, [data]);

    const handleDelete = async () => {
        try {
            // Perform the delete operation using the deleteItem function
            await deleteDoc(formValues);
            showInfo('Item was deleted successfully!');
            Events.trigger(EventsType.RELOAD, {});
            handleCloseModal();
        } catch (err) {
            showWarning('Item was NOT deleted!');
        }
    };


    if (!isOpen) {
        return (
            <>
                <IconButton
                    title={"Delete Data"}
                    onClick={() => handleOpenModal(data)}>
                    <DeleteIcon  />
                </IconButton>
            </>
        );
    }

    return (
        <>
            {data && isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <div className="card-edit">
                            <form>
                                {Object.keys(schema).map((property) => (
                                    <div key={property}>
                                        <b>{property}</b>
                                        <div>{formValues[property]}</div>
                                    </div>
                                ))}

                                    <button type="button" onClick={handleDelete}>
                                        <DeleteIcon />
                                        Delete
                                    </button>
                                    <button type="button" onClick={handleCloseModal}>
                                        Close
                                    </button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DisplayDeleteModal;
