import React from "react";
import AddIcon from '@mui/icons-material/Add';

const WizardSearchStep2: React.FC<{
    selectedKey: string | null;
    selectedObject: any;
    onNext: () => void;
    onAddProperty: () => void;
    propertyValues: Array<{ property: string; value: string | number; operator: string }>;
    onPropertyValueChange: (index: number, updatedProperty: { property: string, value: string | number, operator: string } ) => void;
}> = ({
          selectedKey,
          selectedObject,
          onNext,
          onAddProperty,
          propertyValues,
          onPropertyValueChange,
      }) => {
    if (!selectedKey || !selectedObject) {
        return null;
    }

    const handlePropertyChange = (index: number, newProperty: string) => {
        const updatedPropertyValue = { ...propertyValues[index], property: newProperty };
        onPropertyValueChange(index, updatedPropertyValue);
    };

    const handleOperatorChange = (index: number, newOperator: string) => {
        const updatedPropertyValue = { ...propertyValues[index], operator: newOperator };
        onPropertyValueChange(index, updatedPropertyValue);
    };

    const handleValueChange = (index: number, newValue: string | number) => {
        const updatedPropertyValue = { ...propertyValues[index], value: newValue };
        onPropertyValueChange(index, updatedPropertyValue);
    };


    return (
        <div>
            <br/>
            <h4>Step 2: Select Properties and Values for {selectedKey}</h4>
            {Object.keys(propertyValues).map((property, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center' , paddingBottom: '5px'}}>
                    <select
                        onChange={(e) => handlePropertyChange(index, e.target.value)}
                    >
                        <option>Select a property</option>
                        {Object.keys(selectedObject).map((prop) => (
                            <option key={prop} value={prop}>
                                {prop}
                            </option>
                        ))}
                    </select>


                        &nbsp;

                        <select
                            onChange={(e) => handleOperatorChange(index, e.target.value)}
                            defaultValue="=="
                        >
                            <option>==</option>
                            <option>!=</option>
                            <option>&lt;</option>
                            <option>&gt;</option>
                            <option>REGEX</option>
                        </select>

                 ` &nbsp;

                    <input
                        type="text"
                        placeholder="Enter a value"
                        onChange={(e) => handleValueChange(index, e.target.value)}
                    />
                </div>
            ))}
            <button onClick={onAddProperty}  style={{ width: '140px' }}>
                <AddIcon />
                Add Property
            </button>
            <button onClick={onNext} >Execute Search</button>
        </div>
    );
};

export default WizardSearchStep2
