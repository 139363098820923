import {iBasket, iBasketItem} from "./types";


export class Basket implements iBasket {
    user: string;
    name: string;
    info: string;
    shared: boolean;
    items: iBasketItem[];

    constructor(name: string, user: string) {
        this.name = name;
        this.info = ''
        this.items = []
        this.user = user;
        this.shared = false;
    }

    getItems(): iBasketItem[] {
       return this.items;
    }


    length() {
        return this.items.length;
    }

    static deleteItem(basket: iBasket, id: string): iBasket {
        basket.items = basket.items.filter(item => item.id !== id);
        return basket;
    }

    static getItemById(basket: iBasket, id: string): iBasketItem | undefined {
        return basket.items.find(item => item.id === id);
    }

    static updateItem(basket: iBasket, updatedItem: iBasketItem): iBasket {
        const index = basket.items.findIndex(item => item.id === updatedItem.id);
        if (index > -1) {
            basket.items[index] = updatedItem;
        }
        return basket;
    }
}

