import React, { createContext, useContext, useState } from 'react';

interface MessageContextType {
    showWarning: (message: string) => void;
    showInfo: (message: string) => void;
}

const MessageContext = createContext<MessageContextType | null>(null);

export function useMessage() {
    const context = useContext(MessageContext);
    if (!context) {
        throw new Error('useMessage must be used within a MessageProvider');
    }
    return context;
}

export function ContextMessage({ children }: { children: React.ReactNode }) {
    const [warningMessage, setWarningMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string | null>(null);

    const showWarning = (message: string) => {
        setWarningMessage(message);
        setTimeout(() => {
            setWarningMessage(null);
        }, 10000); // 10 seconds
    };

    const showInfo = (message: string) => {
        setInfoMessage(message);
        setTimeout(() => {
            setInfoMessage(null);
        }, 5000); // 10 seconds
    };

    const value: MessageContextType = {
        showWarning,
        showInfo,
    };

    return (
        <MessageContext.Provider value={value}>
            {children}
            {warningMessage && <div className="message warning">{warningMessage}</div>}
            {infoMessage && <div className="message info">{infoMessage}</div>}
        </MessageContext.Provider>
    );
}
