import {isNotUndefined} from "../utils/string";
import '../utils/arrays';
import DataApiCache from "./Cache";

const URL_PUBCHEM_SERVICE = "https://pubchem.ncbi.nlm.nih.gov/rest/pug_view/data/compound/";
const URL_PUBCHEM_SERVICE_CAS = "https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/";
const URL_PUBCHEM_TAXONOMY = "https://pubchem.ncbi.nlm.nih.gov/rest/autocomplete/compound,gene,taxonomy/";
const URL_PUBCHEM_SERVICE_NAME = "https://pubchem.ncbi.nlm.nih.gov/sdq/sdqagent.cgi?" +
    "infmt=json&outfmt=json&query={%22select%22:%22*%22,%22collection%22:%22_#COMP_SUBS#_%22,%22where%22:" +
    "{%22ands%22:[{%22_#FIELD#_%22:%22_#VALUE#_%22}]}" +
    ",%22order%22:[%22relevancescore,desc%22],%22start%22:1,%22limit%22:1000,%22width%22:1000000,%22listids%22:0}"

const $log: Console = console;

const _api = new DataApiCache(100);

const _getDataByField = async (type:string, name:string, field: string = '*') => {
    $log.debug('... PubChem :: _getDataByField called : ' + name);

    const address = URL_PUBCHEM_SERVICE_NAME
        .replace('_#COMP_SUBS#_', type)
        .replace('_#FIELD#_', field)
        .replace('_#VALUE#_', name)
    ;
    const data = await _api.get_pubchem(address); // await axios.get(address);

    return data;
}

const _getAllDataByCID = async (pubchem_id: string) => {

        $log.debug('... PubChem :: _getAllDataByCID called : ' + pubchem_id);

        const address = URL_PUBCHEM_SERVICE + pubchem_id + '/JSON/?response_type=display';
        const data = await _api.get_pubchem(address); // axios.get(address);
        return data;
}

const _getSection = (records:any, TOCHeading:string) => {
    for (let v in records) {
        if (TOCHeading === records[v].TOCHeading) {
            return records[v];
        }
    }
    return []
}

const _getProperties = (data: any) => {
    return data.Section.map((v: any) => {
        return { name :    v.TOCHeading ,
            description :  v.Description,
            values :       v.Information.map((v2 : any) => {

                let _value;

                if (v2.Value.Number) {
                    _value = v2.Value.Number[0] + ' ' + ( v2.Unit || '');
                }
                else if (v2.Value.StringWithMarkup){
                    _value = v2.Value.StringWithMarkup[0].String;
                }

                return _value;
            })[0]
        }
    });
}

const _getSubsection = (json: any, section_name: string, ) : any => {

    let sub_data = _getSection(json.Section,section_name);
    if (isNotUndefined(sub_data)
        && isNotUndefined(sub_data.Section)
        && sub_data.Section.length > 0) {
        sub_data = _getProperties(sub_data).sortBy('name', 'asc')
    }

    return sub_data;

}


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// EXPORTS
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export const getTaxonomy = async (text:string) => {
    try{
        if (text.length > 2){
            const address = URL_PUBCHEM_TAXONOMY + text;
            const data = await _api.get(address); // axios.get(address);
            return data.dictionary_terms;
        }
        else return { compound:[], gene:[], taxonomy:[] };
    } catch(ex) {
        throw new Error('... cannot get data from PubChem:: getTaxonomy');
    }
}

export const getDataByCAS = async (cas: string) =>{
    try {
        $log.debug('... PubChem :: getDataByCAS called : ' + cas);

        const address = URL_PUBCHEM_SERVICE_CAS + cas + '/cids/JSON';
        const data = await _api.get_pubchem(address); // axios.get(address);
        return data;
    } catch (ex) {
        throw new Error('... cannot get data from Pubchem :: getDataByCAS');
    }
}

export const getAllDataByCID = async (pubchem_id : string): Promise<any> => {
    try {
        const json = await _getAllDataByCID(pubchem_id);
        return json;
    } catch (ex) {
        throw new Error('... cannot get data from PubChem:: getAllDataByCID');
    }
};

export const getDataByField = async (type: string, pubchem_id : string, field: string): Promise<any> => {
    try {
        const json = await _getDataByField(type, pubchem_id, field);
        if (isNotUndefined(json.SDQOutputSet))
            if (isNotUndefined(json.SDQOutputSet[0].rows))
                return { data: json.SDQOutputSet[0].rows };
            else if (json.SDQOutputSet[0].status.code !== 0)
                return { data: [], error: json.SDQOutputSet[0].status.warning[0] }
            else
                return [];
        else
            return [];
    } catch (ex) {
        throw new Error('... cannot get data from PubChem:: getData');
    }
};

/**
 * @param type values = compound | substance
 * @param name name to search by proximity
 */
export const getDataByName = async (type:string, name:string):Promise<any>=> {
    try {
        const json = await _getDataByField(type, name, '*');
        if (isNotUndefined(json.SDQOutputSet))
            if (isNotUndefined(json.SDQOutputSet[0].rows))
                return { data: json.SDQOutputSet[0].rows };
            else if (json.SDQOutputSet[0].status.code !== 0)
                return { data: [], error: json.SDQOutputSet[0].status.warning[0] }
            else
                return [];
        else
            return [];
    } catch (ex) {
        throw new Error('... cannot get data from PubChem:: getChemicalSafety');
    }
}

export const getChemicalSafety = (json: any ) => {
    const data = _getSection(json.Record.Section, 'Chemical Safety');
    return (isNotUndefined(data)
        && isNotUndefined(data.Information)
        && isNotUndefined(data.Information[0].Value.StringWithMarkup))? data.Information[0].Value.StringWithMarkup[0].Markup : null;

}

export const getChemicalAndPhysicalProperties =  (json: any) : any => {

        const data = _getSection(json.Record.Section, 'Chemical and Physical Properties');
        const computedProperties = _getSubsection(data,'Computed Properties');
        const experimentalProperties = _getSubsection(data,'Experimental Properties' );

        return { predicted : computedProperties,
                 experimental : experimentalProperties }

}

export const getStructuresAndNames = (json: any) : any => {
    const data = _getSection(json.Record.Section, 'Names and Identifiers');
    const names = _getSubsection(data,'Computed Descriptors' );

    return names;
}
