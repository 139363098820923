const awsmobile = {
    Auth: {
        region: process.env.REACT_APP_PROJECT_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID?.split('/').pop(),
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGIN,
            redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGOUT,
            scope: ['email', 'openid', 'profile'],
            responseType: 'code',
        }
    }
};

export default awsmobile;
