import React, {useEffect} from 'react';

import {Box, Typography} from "@mui/material";
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

import MaterialReactTable from 'material-react-table';
import Events from '../../../events';
import { EventsType } from '../../../events-types';

import {getStudiesRegEx, getByFilters, STUDIES_METABOLITES} from '../../../api/Studies';
import {Metabolite} from "./types";
import {iBaseCardWithComponentProps, iBaseInnerCardProps } from "../../types";

import Button from "@mui/material/Button";
import {CardWithBaseComponent} from "../../common/BaseCard";
import {getColumns, getInitialColumnState} from "../../../utils/tables";
import {isNotUndefined, isValidIchiKey} from "../../../utils/string";
import {returnPubchemId, returnReferenceID} from "../../../utils/pair_entry";
import {OnChangeFn} from "@tanstack/table-core/src/types";
import {ColumnFiltersState} from "@tanstack/table-core/src/features/Filters";
import {exportToExcel, exportToExcelSelectedRows} from "../../../utils/export_xlsx";


const _MetabolitesTableCard: React.FC<iBaseInnerCardProps> = ({ setIsShow, setError,
                                                        setData, data , setColumnFilters, columnFilters,
                                                        setTitle, setSubtitle, isActive,
                                                                  searchListByFilters
                                                        }) => {
    const UUID = '_MetabolitesTableCard';

    const columnNames = Object.keys(Metabolite);
    const columnsToView = ['COMPOUND_NAME','SPECIES',
                            'BIOLOGICAL_SAMPLE','DOSE','DOSING_REGIMEN','ROUTE','CONCENTRATION_LEVEL'];

    const columns = getColumns(Metabolite);
    const _initial_columns_state= getInitialColumnState(columnNames,columnsToView);

    function searchBy(key:string, value:string){
        setIsShow(false);
        getStudiesRegEx(STUDIES_METABOLITES, '', key, value)
            .then((response) => {

                if (isNotUndefined(response) && response.length > 1) {
                    setTitle(value);
                    setData(response);
                    setIsShow(true);
                }

            }).catch(error => {
                setError(error);
                setIsShow(true);
            });
    }

    function _search(text: string) {
        if (text.length > 0){
            if (isValidIchiKey(text)) {
                searchBy('INCHIKEY', text);
            } else {
                searchBy('COMPOUND_NAME,DETAILS,COMMENT',text);
            }
        }
    }

    const searchEvent = (event: { detail: string }) => {
        if (isActive){
            _search(event.detail);
        }
    }

    const searchWizard = async(event: { detail: any }) => {
        if (isActive && event.detail.component === STUDIES_METABOLITES){
            searchListByFilters?.(getByFilters, STUDIES_METABOLITES, event.detail.filters, 'wizard result');
        }
    }

    // EVENTS ---------------------

    useEffect(() => {
        Events.on(EventsType.SEARCH, searchEvent, UUID);
        Events.on(EventsType.SEARCH_BY_CAS, searchEvent, UUID);
        Events.on(EventsType.SEARCH_WIZARD, searchWizard, UUID);

        return () => {
            Events.off(EventsType.SEARCH, UUID);
            Events.off(EventsType.SEARCH_BY_CAS, UUID);
            Events.off(EventsType.SEARCH_WIZARD, UUID);
        };
    }, [isActive]);

    // RENDER ------------------------

    const handleFilterFnChange: OnChangeFn<ColumnFiltersState> = (filters:any) => {
        setColumnFilters(filters);
    };

    return (
        <p>
            <MaterialReactTable
                columns={columns}
                data={data?? []}
                enableRowSelection //enable some features
                enableColumnOrdering
                enableGlobalFilter={false} //turn off a feature
                enableDensityToggle={true}
                initialState={{
                    columnVisibility: _initial_columns_state,
                    density: 'compact',
                }}

                renderDetailPanel={({ row }) => (
                    <Box
                        sx={{
                            display: 'grid',
                            margin: 'auto',
                            gridTemplateColumns: '1fr 1fr',
                            width: '100%',
                        }}
                    >
                        <Typography><b>Details:</b> {row.original.DETAILS}</Typography>
                        <Typography><b>Comment:</b> {row.original.COMMENT}</Typography>
                        <Typography>{returnReferenceID(row.original)}</Typography>
                        <Typography>{returnPubchemId(row.original)}</Typography>
                    </Box>
                )}


                /* SAVE FILTERS   */

                onColumnFiltersChange={handleFilterFnChange}
                state={{ columnFilters }}

                /* ACTIONS */

                enableRowActions={true}
                renderRowActions={({ row }) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>

                        <Button variant="contained" size="small"
                                color="secondary"
                                onClick={() => {
                                    Events.trigger(EventsType.SEARCH_BY_STUDY_METABOLITE, row.original);
                                }}>
                            ID {row.original._key}
                        </Button>
                    </Box>
                )}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            onClick={() => exportToExcel(data, "Metabolites")}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                        <Button
                            disabled={
                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                            }
                            onClick={() => exportToExcelSelectedRows(table.getSelectedRowModel().rows, "Metabolites")}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Selected Rows
                        </Button>
                    </Box>
                )}
            />
        </p>
    );
};

const MetabolitesTableCard: React.FC<Omit<iBaseCardWithComponentProps, 'cardComponent'>> = ({ header, width, eventGroupToTrigger }) => {
    return <CardWithBaseComponent cardComponent={_MetabolitesTableCard} header={header} width={width} eventGroupToTrigger={eventGroupToTrigger}/>;
};

export default React.memo(MetabolitesTableCard);
