import {isNotEmpty, isNotUndefined, isUndefined} from "./string";
import React from "react";
import {Button} from "@mui/material";
import Events from "../events";
import {EventsType} from "../events-types";
import Card from "react-bootstrap/Card";
import PubchemId from "../components/externals/pubchem/PubchemId";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const isFieldNamed = (fieldName:string, matchingName:string, data: any ): boolean =>  {
    return (fieldName === matchingName && isNotUndefined(data[fieldName]) && data[fieldName] !== '-');
}

const getFieldMappings = (data: any) =>  [
    { name: 'PUBCHEM_CID', component: <PubchemId data={data} /> },
    { name: 'INCHIKEY', component: returnKEY(data.INCHIKEY || data.inchikey, 'INCHIKEY', EventsType.SEARCH_BY_INCHIKEY) },
    { name: 'TEST_METHOD_CODE', component: returnKEY(data.TEST_METHOD_CODE, 'TEST_METHOD_CODE', EventsType.SEARCH_BY_PRODUCT_ANALYSIS_METHOD_CODE) },
    { name: 'CLASS', component: returnKEY(data.CLASS, 'CLASS', EventsType.SEARCH_BY_PRODUCT_ANALYSIS_CLASS) },
    { name: 'REF_ARTICLE', component: returnReferenceID(data) },
    { name: 'REFERENCE_ID', component: returnReferenceID(data) },
    //      { name: 'REFERENCE_PMID', component: returnPubMedId(data) },
    { name: 'CBD_ID', component: returnCBDID(data) },
    { name: 'SOURCE', component: returnLinks(data) },
    { name: 'LINK', component: returnLinks(data) },
    { name: 'CAS', component: returnKEY(data.CAS, 'CAS', EventsType.SEARCH_BY_CAS)},
    { name: 'chebi_id', component: returnChEBIId(data) },
    { name: 'foodb_id', component: returnFoodDBId(data) },
    { name: 'chemspider_id', component: returnChemSpiderId(data) },
    { name: 'drugbank_id', component: returnDrugBankId(data) },
    { name: 'kegg_id', component: returnKEGGId(data) },
    { name: 'knapsack_id', component: returnKNApSAcKID(data) },
    { name: 'metlin_id', component: returnMetlinID(data) },
    { name: 'pdb_id', component: returnPubMedId(data) },
    { name: 'wikipedia_id', component: returnWikipediaId(data) },
    { name: 'biocyc_id', component: returnBioCycID(data) },
    { name: 'phenol_explorer_compound_id', component: returnPhenolExplorerCID(data) },
];

/**
 * Returning all fields of a component defined,
 * parsing the properties defined and rendering each of them according to a predefined mapping
 * @param COMPONENT
 * @param data
 */
export const renderFields = (COMPONENT: any, data: any)  =>{
    const fieldNames = Object.keys(COMPONENT);

    return (
        <>
            {fieldNames.map((fieldName) => (
                <React.Fragment key={fieldName}>
                    {renderField(data, fieldName)}
                </React.Fragment>
            ))}
        </>
    );
}

// -----------------------------------------------------------------------------------------

export const renderField = (data: any, fieldName: string, digits: number = 2) => {
    const _fieldValue = data[fieldName];
    const fieldMappings= getFieldMappings(data);

    for (const mapping of fieldMappings) {
        if (isFieldNamed(fieldName, mapping.name, data)) {
            return mapping.component;
        }
    }

    const fieldValue = (typeof _fieldValue === 'number' && fieldName !== 'STRAIN_ID' && fieldName !== 'ANONYMOUS_PRODUCER')
        ?  (_fieldValue % 1 === 0 ? _fieldValue.toFixed(0) : _fieldValue.toFixed(digits))
        : (typeof _fieldValue === 'boolean') ? String(_fieldValue) : _fieldValue;

    return (
        <>
            <b>{fieldName.replace("_", " ")}</b>: {fieldValue}
            <br />
        </>
    );
};


// -----------------------------------------------------------------------------------------
// Below: all concrete methods to return a HTML code for each type of data
// -----------------------------------------------------------------------------------------

export const returnLinkField = (id: any,
                                displayedName: string,
                                urlTemplate: (id: string) => string ) => {
        if (typeof id === 'undefined' || id === 'undefined') {
            return null;
        }

        return (
            <>
                <b>{displayedName} : </b>&nbsp;
                <React.Fragment key={id}>
                    <Card.Link target="_blank" href={urlTemplate(id)}>
                        {id}
                    </Card.Link>
                </React.Fragment>
                <br />
            </>
        );
}

export const returnCBDID = (data:any) => {
    return returnLinkField((data.CBD_ID || data.accession), 'CBD ID', (id: string) => `https://cannabisdatabase.ca/compounds/${id}`);
}

export const returnPubMedId = (data:any) => {
    return returnLinkField((data.REFERENCE_PMID || data.pubchem_compound_id), 'PUBMED ID', (id: string) => `https://pubmed.ncbi.nlm.nih.gov/${id}`);
}

export const returnDrugBankId = (data:any) => {
    return returnLinkField((data.drugbank_id), 'DRUGBANK ID', (id: string) => `https://go.drugbank.com/drugs/${id}`);
}

export const returnChemSpiderId = (data:any) => {
    return returnLinkField((data.chemspider_id), 'CHEMSPIDER ID', (id: string) => `https://www.chemspider.com/Chemical-Structure.${id}.html`);
}

export const returnKEGGId = (data:any) => {
    return returnLinkField((data.kegg_id), 'KEGG ID', (id: string) => `https://www.genome.jp/dbget-bin/www_bget?cpd:${id}`);
}

export const returnChEBIId = (data:any) => {
    return returnLinkField((data.chebi_id), 'CHEBI ID', (id: string) => `https://www.ebi.ac.uk/chebi/searchId.do?chebiId=${id}`);
}

export const returnChemblId = (data:any) => {
    return returnLinkField((data.chembl_id), 'CHEMBL ID', (id: string) => `https://www.ebi.ac.uk/chembl/compound_report_card/${id}`);
}

export const returnDrugCentralId = (data:any) => {
    return returnLinkField((data.drugcentral_id), 'DRUGCENTRAL ID', (id: string) => `https://drugcentral.org/drugcard/${id}`);
}

export const returnKNApSAcKID = (data:any) => {
    return returnLinkField((data.knapsack_id), 'KNApSAcK ID', (id: string) => `http://kanaya.naist.jp/knapsack_jsp/information.jsp?word=${id}`);
}

export const returnFoodDBId = (data:any) => {
    return returnLinkField((data.foodb_id), 'FOODDB ID', (id: string) => `https://foodb.ca/compounds/${id}`);
}

export const returnWikipediaId = (data:any) => {
    return returnLinkField((data.wikipedia_id), 'WIKIPEDIA ID', (id: string) => `https://en.wikipedia.org/wiki/${id}`);
}

export const returnBioCycID = (data:any) => {
    return returnLinkField((data.biocyc_id), 'BIOCYC ID', (id: string) => `https://biocyc.org/compound?orgid=META&id=${id}`);
}

export const returnMetlinID = (data:any) => {
    return returnLinkField((data.metlin_id), 'METLIN ID', (id: string) => `https://metlin.scripps.edu/compound/${id}`);
}

export const returnPhenolExplorerCID = (data:any) => {
    return returnLinkField((data.phenol_explorer_compound_id), 'PHENOL EXPLORER CID', (id: string) => `http://phenol-explorer.eu/compounds/${id}`);
}

export const returnReferenceID = (data:any) => {
    const _data = String(data.REFERENCE_ID || data.REF_ARTICLE).trim();
    if (isUndefined(_data) || (_data === 'undefined')) {
        return (<></>);
    }
    else {
        const values: string[] = _data.split(',');
        return (
            <>
                <b> REFERENCES : </b> &nbsp;
                {values.map((v) => (
                    <React.Fragment key={v}>
                        <Button variant="contained" size="small"
                                color="secondary"
                                onClick={() => {
                                    Events.trigger(EventsType.SEARCH_BY_REFERENCE_FIELD, v);
                                }}>
                            {v}
                        </Button>&nbsp;
                        &nbsp;&nbsp;
                    </React.Fragment>
                ))}
            </>
        );
    }
}

export const returnCompoundName = (data:any) => {
    const _name = data.NAME || data.SYNONYMS || data.INCHIKEY;

    return (
      <>
          <React.Fragment key={data.INCHIKEY}>
              <Button variant="contained" size="small"
                      color="secondary"
                      onClick={() => {
                          Events.trigger(EventsType.SEARCH_BY_INCHIKEY, data.INCHIKEY);
                      }}>
                  {_name}
              </Button>
              <br/>
          </React.Fragment>
      </>
    );
}

/**
 * Used when is data that requies a button to trigger a search event and a copy icon to copy the content value of the button displayed
 * @param _data
 * @param data_type
 * @param event
 */
export const returnKEY = (_data:string, data_type:string, event: EventsType) => {

    if (isUndefined(_data) || (!Array.isArray(_data) && typeof _data !== 'string')) {
        return (<></>);
    }
    else {
        let values: string[]  = [];

        if (typeof _data === 'string') {
            values = _data.split(',');
        }

        return (
            <>
                <b>{data_type} : </b> &nbsp;
                {values.map((v) => (
                    <React.Fragment key={v} >
                              <Button variant="contained" size="small"
                                      style={{ margin: '5px'}}
                                      color="secondary"
                                      onClick={() => {
                                          Events.trigger(event, v);
                                      }}>
                            {v}
                        </Button>
                       &nbsp;
                    </React.Fragment>
                ))} &nbsp;
                { (values.length > 1) && (
                    <React.Fragment>
                        <Button variant="contained" size="small"
                                color="info"
                                onClick={() => {
                                    Events.trigger(event, _data);
                                }}>
                            ALL {data_type}S TOGETHER
                        </Button> <br/>
                    </React.Fragment>
                    )}
                &nbsp;
                <ContentCopyIcon  style={{ cursor: 'pointer' }} onClick={() => {navigator.clipboard.writeText(_data)}} />
                <br/>
            </>
        );
    }
}



export const returnPubchemId = (data: any) => {
    const _data = String(data.PUBCHEM_CID).trim();
    if (isUndefined(_data) || (_data === 'undefined')) {
        return (<></>);
    }
    else {
        const cids: string[] = _data.split(',');
        return (
            <>
                <b>PUBCHEM CID : </b> &nbsp;
                {cids.map((cid) => (
                    <React.Fragment key={cid}>
                        <Button variant="contained" size="small"
                                color="secondary"
                                onClick={() => {
                                    Events.trigger(EventsType.SEARCH_BY_PUBCHEM_CID, cid);
                                }}>
                            {cid}
                        </Button>
                        &nbsp;
                        <Card.Link target="_blank" href={`https://pubchem.ncbi.nlm.nih.gov/compound/${cid}`}>
                            link
                        </Card.Link>
                        <br />
                    </React.Fragment>
                ))}
            </>
        );
    }
}

export const returnLinks = (data:any) => {
    const _fieldValue = data.SOURCE || data.LINK;
    if (isNotEmpty(_fieldValue)) {
        let fieldValue;
        const fieldValues = _fieldValue.split(';');
        fieldValue = fieldValues.map((value: string, index: number) => (
            <Card.Link key={index} target="_blank" href={value}>
                Link-{index + 1}
            </Card.Link>
        ));

        return (
            <>
                <b>SOURCE</b> : {fieldValue}
                <br/>
            </>
        );
    }

    return null;
}


