import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import {generateUUID} from "../../utils/string";
import { ChartData, ChartPieProps } from "./types";
import { defaultGenerateColors, defaultBuildLabels } from "./Chart.Common";

export const ChartPie: React.FC<ChartPieProps> = ({ data,
                                                      styleParam,
                                                      buildLabels = defaultBuildLabels,
                                                      generateColors = defaultGenerateColors }) => {

    const UUID = generateUUID();

    const chartData: ChartData = {
        labels: buildLabels(data),
        datasets: [
            {
                data: data.map((row) => row.VALUE),
                backgroundColor: generateColors(data.length),
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    };

    return (
        <>
            <Pie data={chartData} options={chartOptions} id={UUID} style={styleParam ? styleParam : {}}/>
        </>
    );
};
