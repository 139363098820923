import React, {useContext, useEffect, useState, useCallback } from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {IconButton} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {EventsType} from "../../events-types";
import Events from "../../events";
import {generateUUID} from "../../utils/string";

import { BasketsContext } from "../../App.Context";

interface StorageIconProps {
    title: string;
    header: string;
    data: any[];
    filter: any[];
    id: string;
}

const StorageIcon: React.FC<StorageIconProps> = ( props:StorageIconProps) => {

    const multiStorage = useContext(BasketsContext);
    const UUID = '_StorageIcon' + generateUUID();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [storages, setStorages] = useState<string[]>([]);

    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        refreshList();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (storageKey: string) => {

        Events.trigger(EventsType.BUCKET_ADD, {
                STORAGE_KEY: storageKey,
                TITLE: props.title,
                HEADER: props.header,
                DATA: props.data,
                filter: props.filter,
                id: props.id
        });

        handleClose();
    };

    const refreshList = useCallback(async () => {
        await multiStorage.load();
        setStorages(await multiStorage.getAllBasketsNames());
    }, []);

    useEffect(() => {

        const fetchData = async () => {
            setStorages(await multiStorage.getAllBasketsNames());
        };

        fetchData();

        Events.on(EventsType.BUCKET_TO_DELETE, refreshList, UUID);
        Events.on(EventsType.BUCKET_RENAME, refreshList, UUID);

        return () => {
            Events.off(EventsType.BUCKET_TO_DELETE, UUID);
            Events.off(EventsType.BUCKET_RENAME, UUID);
        };

    },  []);


    return (
        <span>
            <IconButton
                title={"Add Card data into a Bucket"}
                onClick={handleIconClick}  onDoubleClick={refreshList} color="primary"  >
                <AddShoppingCartIcon  />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {storages.map((storageKey: string) => (
                        <MenuItem key={storageKey}
                                  onClick={() => handleMenuItemClick(storageKey)}
                        >{storageKey}</MenuItem>
                    ))}
            </Menu>
        </span>
    );
};

export default StorageIcon;
