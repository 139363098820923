import React, {useEffect, useState} from 'react';
import Button from "@mui/material/Button";

interface EditableTextProps {
    text: string;
    width: string;
    onSave: (newText: string) => void;
}

const EditableText: React.FC<EditableTextProps> = ({ text, width = '200px', onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text || ' .... click to edit');

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedText(text);
    };

    const handleSave = () => {
        onSave(editedText);
        setIsEditing(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedText(e.target.value);
    };

    useEffect(() => {
        setEditedText(text);
    }, [text]);

    if (isEditing) {
        return (
            <div style={{width: width}}>
                <input style={{width: '100%'}}
                       type="text"
                       value={editedText}
                       onChange={handleChange} /> &nbsp;
                <Button  variant="contained"  color="primary" onClick={handleSave}>Save</Button> &nbsp;
                <Button  variant="contained"  color="secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        );
    }
    else {
        return (
            <div >
                <span onClick={handleEdit} >{editedText}</span>
            </div>
        );
    }
};

export default EditableText;
